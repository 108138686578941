import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Beacon,
  BeaconEnvironment,
  FromToFilter,
  useEnvironmentHistory,
} from "../../../api";
import { useComputeFromToDateRange } from "../../../util/useComputeFromToDateRange";
import { Chart, SeriesDefintion } from "../../Chart";
import { DownloadEnvironmentMessages } from "../../DownloadEnvironmentMessages";
import { BeaconBatteryDrawer } from "./BeaconBatteryDrawer";

interface BeaconBatteryCardProps {
  beacon: Beacon;
  selectedFromToFilter:
    | (FromToFilter & number)
    | (FromToFilter & {
        from: Date;
        to: Date;
      });
  onSelectCustomTimeFrame: (from: Date, to: Date) => void;
}

export function BeaconBatteryCard({
  beacon,
  selectedFromToFilter,
  onSelectCustomTimeFrame,
}: BeaconBatteryCardProps) {
  const [batteryDrawerVisible, setBatteryDrawerVisible] = useState(false);

  const { t } = useTranslation();

  const handleDrawerOpenClose = (state: boolean) => {
    setBatteryDrawerVisible(state);
  };

  const environmentHistory = useEnvironmentHistory(
    undefined,
    beacon.id,
    selectedFromToFilter
  );

  const environmentFromToDateRange = useComputeFromToDateRange(
    environmentHistory.lastLoadedAt,
    selectedFromToFilter
  );

  const environmentSeries: SeriesDefintion<BeaconEnvironment>[] = [
    { title: t("battery-voltage"), value: (p) => p.value.batt.v },
  ];

  return (
    <div className="card">
      <div className="card-header-relative">{t("battery-voltage")}</div>
      <div style={{ height: "90%" }}>
        <Chart
          onZoom={onSelectCustomTimeFrame}
          items={environmentHistory.value || []}
          loading={environmentHistory.loading}
          series={environmentSeries}
          from={environmentFromToDateRange.from}
          to={environmentFromToDateRange.to}
        />
      </div>
      {!!environmentHistory.value?.length && (
        <DownloadEnvironmentMessages
          beaconId={beacon.id}
          environmentMessages={environmentHistory.value || []}
          style={{ position: "absolute", bottom: 10, right: 40 }}
          from={environmentFromToDateRange.from}
          to={environmentFromToDateRange.to}
        />
      )}
      <BeaconBatteryDrawer
        beaconId={beacon.id}
        environmentHistory={environmentHistory}
        environmentFromToDateRange={environmentFromToDateRange}
        environmentSeries={environmentSeries}
        onSelectCustomTimeFrame={onSelectCustomTimeFrame}
        batteryDrawerVisible={batteryDrawerVisible}
        onCloseDrawer={handleDrawerOpenClose}
      />
      <Button
        className="show-card-drawer-button"
        type="primary"
        onClick={() => handleDrawerOpenClose(true)}
        icon={<PlusOutlined />}
      ></Button>
    </div>
  );
}
