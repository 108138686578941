import { useContext } from "react";
import { AuthContext } from "../../contexts/authContext";
import { BeaconsBeaconTable } from "./BeaconsBeaconTable";

export function Beacons() {
  const authContext = useContext(AuthContext);
  const currentUserIsSolutionOperator =
    authContext.currentUser?.accessToken?.hasRole("solution-operator") ?? false;


  return <BeaconsBeaconTable currentUserIsSolutionOperator={currentUserIsSolutionOperator} />;
}
