import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { BeaconLifecycle } from "../../../api";
import { PagedApiState } from "../../../api/useApiState";
import "../../../styles/card.scss";
import { BeaconEventTable } from "./BeaconEventTable";

interface BeaconEventDrawerProps {
  lifecycleHistory: PagedApiState<BeaconLifecycle>;
  eventDrawerVisible: boolean;
  onCloseDrawer: (visible: boolean) => void;
}

export function BeaconEventDrawer({
  lifecycleHistory,
  eventDrawerVisible,
  onCloseDrawer,
}: BeaconEventDrawerProps) {
  const { t } = useTranslation();
  const closeDrawer = () => {
    onCloseDrawer(false);
  };

  return (
    <Drawer
      aria-label="beacon-event-drawer"
      bodyStyle={{ margin: "1.5rem", padding: "0px" }}
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{t("beacon:events")}</span>
          <Button
            type="text"
            className="close-card-drawer-button"
            icon={<CloseOutlined />}
            onClick={closeDrawer}
          />
        </div>
      }
      placement="right"
      closable={false}
      onClose={closeDrawer}
      visible={eventDrawerVisible}
      width={1200}
    >
      <BeaconEventTable lifecycleHistory={lifecycleHistory} />
    </Drawer>
  );
}
