import React from 'react';
import { useTranslation } from 'react-i18next';
import { BeaconPosition } from '../api';
import { Button } from 'antd';
import { CsvColumn, useDownloadCsv } from '../util';

interface DownloadPositionsProps {
    style?: React.CSSProperties;
    beaconId: string;
    positions: BeaconPosition[];
    from: Date;
    to: Date;
}

const columns: CsvColumn<BeaconPosition>[] = [
    {
        title: 'BeaconId',
        value: (_p, _c, i) => i
    },
    {
        title: 'MeasuredAt',
        value: (p, c) => p.measuredAt.toLocaleString(c)
    },
    {
        title: 'Latitude',
        value: (p, c) => p.value.pos.lat.toLocaleString(c, { maximumSignificantDigits: 20 })
    },
    {
        title: 'Longitude',
        value: (p, c) => p.value.pos.lon.toLocaleString(c, { maximumSignificantDigits: 20 })
    },
    {
        title: 'Altitude',
        value: (p, c) => p.value.pos.alt.toLocaleString(c, { maximumSignificantDigits: 20 })
    },
    {
        title: 'Horizontal Accuracy',
        value: (p, c) => p.value.md.hAcc.toLocaleString(c, { maximumSignificantDigits: 20 })
    },
    {
        title: 'Vertical Accuracy',
        value: (p, c) => p.value.md.vAcc.toLocaleString(c, { maximumSignificantDigits: 20 })
    },
    {
        title: 'Timestamp Accuracy',
        value: (p, c) => p.value.md.tAcc.toLocaleString(c, { maximumSignificantDigits: 20 })
    },
    {
        title: 'HDOP',
        value: (p, c) => p.value.md.hDop.toLocaleString(c, { maximumSignificantDigits: 20 })
    },
    {
        title: 'PDOP',
        value: (p, c) => p.value.md.pDop.toLocaleString(c, { maximumSignificantDigits: 20 })
    },
    {
        title: 'VDOP',
        value: (p, c) => p.value.md.vDop.toLocaleString(c, { maximumSignificantDigits: 20 })
    },
    {
        title: 'Satellites Used',
        value: (p, c) => p.value.md.satU.toLocaleString(c, { maximumSignificantDigits: 20 })
    },
    {
        title: 'State Of Position',
        value: (p, c) => p.value.md.pst.toLocaleString(c, { maximumSignificantDigits: 20 })
    },
    {
        title: 'Carrier Phase Range Solution',
        value: (p, c) => p.value.md.cprs.toLocaleString(c, { maximumSignificantDigits: 20 })
    }
];

export function DownloadPositions(props: DownloadPositionsProps) {

    const { t } = useTranslation();

    const { positions, from, to, beaconId } = props;
    const download = useDownloadCsv(beaconId, positions, columns, `BeaconPositions_${beaconId}_${from.toISOString()}_${to.toISOString()}.csv`);

    return <div style={props.style}>
        <Button type="dashed" onClick={download}>{t('save-as-csv')}</Button>
    </div>;
}