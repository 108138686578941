import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Beacon,
  BeaconPosition,
  FromToFilter,
  usePositionHistory,
} from "../../../api";
import { DualAxisLineChart } from "../../../components/MultiAxisChart";
import { useComputeFromToDateRange } from "../../../util/useComputeFromToDateRange";
import { BeaconTelemetryFilterValue } from "../../BeaconTelemetryFilter";
import { SeriesDefintion } from "../../Chart";
import { DownloadPositions } from "../../DownloadPositions";
import { BeaconLocationDrawer } from "./BeaconLocationDrawer";

interface BeaconLocationCardProps {
  selectedFromToFilter:
    | (FromToFilter & number)
    | (FromToFilter & {
        from: Date;
        to: Date;
      });
  filter: BeaconTelemetryFilterValue;
  onSelectCustomTimeFrame: (from: Date, to: Date) => void;
  beacon: Beacon;
}

export function BeaconLocationCard({
  selectedFromToFilter,
  onSelectCustomTimeFrame,
  filter,
  beacon,
}: BeaconLocationCardProps) {
  const [locationBatteryVisible, setLocationBatteryVisible] = useState(false);

  const { t } = useTranslation();

  const handleDrawerOpenClose = (state: boolean) => {
    setLocationBatteryVisible(state);
  };

  const positionHistory = usePositionHistory(
    undefined,
    beacon.id,
    selectedFromToFilter
  );

  const positionFromToDateRange = useComputeFromToDateRange(
    positionHistory.lastLoadedAt,
    selectedFromToFilter
  );

  let positions: BeaconPosition[] = positionHistory.value || [];
  if (filter === "GpsOnly") {
    positions = positions.filter((p) => p.value.md.pst === 1);
  } else if (filter === "AccurateCorrectedPosition") {
    positions = positions.filter((p) => p.value.md.pst === 3);
  }

  const positionSeries: SeriesDefintion<BeaconPosition>[] = [
    { title: t("horizontal-accuracy"), value: (p) => p.value.md.hAcc },
    { title: t("carrier-phase-range-solution"), value: (p) => p.value.md.cprs },
    { title: t("vertical-accuracy"), value: (p) => p.value.md.vAcc },
    { title: t("timestamp-accuracy"), value: (p) => p.value.md.tAcc },
    { title: t("hdop"), value: (p) => p.value.md.hDop },
    { title: t("pdop"), value: (p) => p.value.md.pDop },
    { title: t("vdop"), value: (p) => p.value.md.vDop },
    { title: t("satellites-used"), value: (p) => p.value.md.satU },
    { title: t("state-of-position"), value: (p) => p.value.md.pst },
    { title: t("latitude"), value: (p) => p.value.pos.lat },
    { title: t("longitude"), value: (p) => p.value.pos.lon },
    { title: t("altitude"), value: (p) => p.value.pos.alt },
  ];

  return (
    <div className="card">
      <div className="card-header-relative"> {t("beacon:position-data")}</div>
      <div style={{ height: "90%" }}>
        <DualAxisLineChart
          onZoom={onSelectCustomTimeFrame}
          items={positions}
          loading={positionHistory.loading}
          series={positionSeries.filter(
            (_, index) => index === 0 || index === 4
          )}
          from={positionFromToDateRange.from}
          to={positionFromToDateRange.to}
        />
      </div>
      {!!positions.length && (
        <DownloadPositions
          beaconId={beacon.id}
          positions={positions}
          style={{ position: "absolute", bottom: 10, right: 40 }}
          from={positionFromToDateRange.from}
          to={positionFromToDateRange.to}
        />
      )}
      <BeaconLocationDrawer
        beaconId={beacon.id}
        positions={positions}
        positionHistory={positionHistory}
        positionSeries={positionSeries}
        positionFromToDateRange={positionFromToDateRange}
        locationDrawerVisible={locationBatteryVisible}
        onSelectCustomTimeFrame={onSelectCustomTimeFrame}
        onCloseDrawer={handleDrawerOpenClose}
      />
      <Button
        className="show-card-drawer-button"
        type="primary"
        onClick={() => handleDrawerOpenClose(true)}
        icon={<PlusOutlined />}
      ></Button>
    </div>
  );
}
