import { useTranslation } from "react-i18next";
import "./Map.css";

interface BeaconMapLegendProps {
  style?: React.CSSProperties;
}

export function BeaconMapLegend({ style }: BeaconMapLegendProps) {
  const { t } = useTranslation();

  const legendItems = [
    { color: "green", text: t("beacon:on-accurate-position") },
    { color: "orange", text: t("beacon:on-inaccurate-position") },
    { color: "red", text: t("beacon:on-error") },
    { color: "grey", text: t("beacon:off") },
  ];

  return (
    <div className="beacon-map-info-button-popup" style={style}>
      {legendItems.map((item, index) => (
        <p key={index}>
          <span style={{ color: item.color }}>●</span> {item.text}
        </p>
      ))}
    </div>
  );
}
