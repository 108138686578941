import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Beacon,
  BeaconPosition,
  BeaconWithPosition,
  FromToFilter,
} from "../../../api";
import { ApiState } from "../../../api/useApiState";
import { maxBy } from "../../../util";
import { BeaconTelemetryFilterValue } from "../../BeaconTelemetryFilter";
import { BeaconMapDetails } from "./BeaconMapDetails";
import { BeaconMapLegend } from "./BeaconMapLegend";

import "./Map.css";

interface BeaconMapDrawerProps {
  positionHistory: ApiState<BeaconPosition[] | undefined>;
  beacon: Beacon;
  selectedFromToFilter:
    | (FromToFilter & number)
    | (FromToFilter & {
        from: Date;
        to: Date;
      });
  filter: BeaconTelemetryFilterValue;
  positions: BeaconPosition[];
  mapDrawerVisible: boolean;
  onCloseDrawer: (visible: boolean) => void;
}

export function BeaconMapDrawer({
  positionHistory,
  beacon,
  selectedFromToFilter,
  filter,
  positions,
  mapDrawerVisible,
  onCloseDrawer,
}: BeaconMapDrawerProps) {
  const { t } = useTranslation();

  const [isMapInfoVisible, setIsMapInfoVisible] = useState(false);

  const newestPosition = maxBy(positionHistory.value || [], (p) =>
    p.measuredAt.getTime()
  )?.item;

  const latestPositionAsFallbackForMap =
    typeof selectedFromToFilter === "number"
      ? (beacon as BeaconWithPosition)?.position
      : undefined;

  const toggleMapInfo = (visible: boolean) => {
    setIsMapInfoVisible(visible);
  };

  const closeDrawer = () => {
    onCloseDrawer(false);
  };

  return (
    <Drawer
      aria-label="beacon-map-drawer"
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{t("beacon:position-map")}</span>
          <div className="beacon-map-card-info-div">
            <Button
              type="text"
              className="info-button"
              icon={<InfoCircleOutlined />}
              onMouseEnter={() => toggleMapInfo(true)}
              onMouseLeave={() => toggleMapInfo(false)}
            />
            <Button
              type="text"
              className="close-card-drawer-button"
              icon={<CloseOutlined />}
              onClick={closeDrawer}
            />
            {isMapInfoVisible && <BeaconMapLegend style={{ right: "80px" }} />}
          </div>
        </div>
      }
      placement="right"
      closable={false}
      onClose={closeDrawer}
      visible={mapDrawerVisible}
      width={1200}
    >
      <BeaconMapDetails
        loading={positionHistory.loading}
        lastLoadedAt={positionHistory.lastLoadedAt}
        beacon={beacon}
        position={newestPosition ?? latestPositionAsFallbackForMap}
        polyLines={
          filter === "LatestOnly"
            ? []
            : [
                {
                  line: positions.map((p) => ({
                    lat: p.value.pos.lat,
                    lon: p.value.pos.lon,
                  })),
                },
              ]
        }
      />
    </Drawer>
  );
}
