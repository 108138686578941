import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Beacon,
  BeaconWithPosition,
  FromToFilter,
  usePositionHistory,
} from "../../../api";
import { maxBy } from "../../../util";
import { BeaconTelemetryFilterValue } from "../../BeaconTelemetryFilter";
import { BeaconMapDetails } from "./BeaconMapDetails";
import { BeaconMapDrawer } from "./BeaconMapDrawer";
import { BeaconMapLegend } from "./BeaconMapLegend";
import "./Map.css";

export interface BeaconMapCardProps {
  beacon: Beacon;
  selectedFromToFilter:
    | (FromToFilter & number)
    | (FromToFilter & {
        from: Date;
        to: Date;
      });
  filter: BeaconTelemetryFilterValue;
}

export function BeaconMapCard({
  beacon,
  selectedFromToFilter,
  filter,
}: BeaconMapCardProps) {
  const [mapDrawerVisible, setMapDrawerVisible] = useState(false);
  const { t } = useTranslation();
  const [isMapInfoVisible, setIsMapInfoVisible] = useState(false);

  const positionHistory = usePositionHistory(
    undefined,
    beacon.id,
    selectedFromToFilter
  );

  let positions = positionHistory.value || [];
  positions = positions.filter((p) => {
    if (filter === "GpsOnly") return p.value.md.pst === 1;
    if (filter === "AccurateCorrectedPosition") return p.value.md.pst === 3;
    return true;
  });

  const newestPosition = maxBy(positionHistory.value || [], (p) =>
    p.measuredAt.getTime()
  )?.item;

  const latestPositionAsFallbackForMap =
    typeof selectedFromToFilter === "number"
      ? (beacon as BeaconWithPosition)?.position
      : undefined;

  const toggleMapInfo = (state: boolean) => {
    setIsMapInfoVisible(state);
  };

  const toggleDrawer = (state: boolean) => {
    setMapDrawerVisible(state);
  };

  return (
    <div className="card">
      <div className="card-header-relative">{t("beacon:position-map")}</div>
      <div style={{ height: "100%" }}>
        <BeaconMapDetails
          loading={positionHistory.loading}
          lastLoadedAt={positionHistory.lastLoadedAt}
          beacon={beacon}
          position={newestPosition ?? latestPositionAsFallbackForMap}
          polyLines={
            filter === "LatestOnly"
              ? []
              : [
                  {
                    line: positions.map((p) => ({
                      lat: p.value.pos.lat,
                      lon: p.value.pos.lon,
                    })),
                  },
                ]
          }
        />
      </div>
      <BeaconMapDrawer
        positionHistory={positionHistory}
        beacon={beacon}
        selectedFromToFilter={selectedFromToFilter}
        filter={filter}
        positions={positions}
        mapDrawerVisible={mapDrawerVisible}
        onCloseDrawer={toggleDrawer}
      />
      <div className="beacon-map-card-info-div">
        <Button
          aria-label="map-details-info-button"
          type="default"
          className="info-button"
          onMouseEnter={() => toggleMapInfo(true)}
          onMouseLeave={() => toggleMapInfo(false)}
          icon={<InfoCircleOutlined />}
        />
        <Button
          aria-label="show-drawer-button"
          type="primary"
          onClick={() => toggleDrawer(true)}
          icon={<PlusOutlined />}
        ></Button>
        {isMapInfoVisible && <BeaconMapLegend style={{ bottom: "40px" }} />}
      </div>
    </div>
  );
}
