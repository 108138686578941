import { Alert, Spin } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Crosshair,
  DiscreteColorLegend,
  LineSeries,
  LineSeriesPoint,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis";
import { maxBy, minBy, useDimension } from "../util";
import "./Chart.css";

export interface SeriesDefintion<T> {
  value: (r: T) => number | undefined;
  title: string;
}

interface HasMeasuredAtDate {
  measuredAt: Date;
}

interface ChartProps<T> {
  from: Date;
  to: Date;
  lastRefreshDone?: Date;
  loading: boolean;
  atDate?: Date;
  items: T[];
  series: SeriesDefintion<T>[];
  mode?: "showAllSeries" | "userSelectSeries";
  onZoom?(from: Date, to: Date): void;
}

function toDate(
  offsetX: number,
  chartRef: React.MutableRefObject<any>,
  from: Date,
  to: Date
): Date {
  const g = chartRef.current.querySelector("g");
  const leftMostX = g.transform.baseVal[0].matrix.e;
  const width = g.querySelector("line").x2.baseVal.value;
  const relativeX = Math.max(0, Math.min(1, (offsetX - leftMostX) / width));
  return new Date(from.getTime() + (to.getTime() - from.getTime()) * relativeX);
}

export function Chart<T extends HasMeasuredAtDate>(props: ChartProps<T>) {
  const [crossHairValues, setCrossHairValues] = useState<
    { p: LineSeriesPoint | undefined }[]
  >(props.series.map((s) => ({ p: undefined })));
  const [selectedSeries, setSelectedSeries] = useState([0]);
  const mode = props.mode || "showAllSeries";
  const { t, i18n } = useTranslation();

  const chartRef = useRef<any>();
  const chartDimensions = useDimension(chartRef);

  const [zoomFrom, setZoomFrom] = useState<number>();
  const [zoomTo, setZoomTo] = useState<number>();

  const onMouseLeave = useCallback(() => {
    setZoomFrom(undefined);
    setZoomTo(undefined);
    setCrossHairValues(props.series.map((s) => ({ p: undefined })));
  }, [setCrossHairValues, setZoomFrom, setZoomTo]);

  const onZoomStart = useCallback(
    (e: React.MouseEvent) => {
      setZoomFrom(e.nativeEvent.offsetX);
      setZoomTo(undefined);
    },
    [setZoomFrom]
  );

  const onZoomMove = useCallback(
    (e: React.MouseEvent) => {
      setZoomTo(e.nativeEvent.offsetX);
    },
    [setZoomTo]
  );

  const onZoomEnd = useCallback(
    (e: React.MouseEvent) => {
      if (zoomFrom === undefined || zoomTo === undefined) {
        return;
      }

      try {
        const left = Math.min(zoomFrom, zoomTo);
        const right = Math.max(zoomFrom, zoomTo);

        if (right - left > 10 && props.onZoom) {
          const from = toDate(left, chartRef, props.from, props.to);
          const to = toDate(right, chartRef, props.from, props.to);
          props.onZoom(from, to);
        }
      } finally {
        setZoomFrom(undefined);
        setZoomTo(undefined);
      }
    },
    [
      setZoomFrom,
      setZoomTo,
      chartRef,
      props.onZoom,
      props.from,
      props.to,
      zoomFrom,
      zoomTo,
    ]
  );

  if (!props.items || !props.items.length) {
    return props.loading ? (
      <Spin className="centered" />
    ) : (
      <div style={{ height: "100%", position: "relative" }}>
        <Alert message={t("no-data")} type="info" showIcon />
      </div>
    );
  }

  props.items.sort((a, b) => (a.measuredAt > b.measuredAt ? 1 : -1));

  const chv = crossHairValues
    .map((c, i) => ({ seriesIndex: i, point: c.p }))
    .filter((c) => !!c.point);

  let activeSeries = props.series;
  if (mode === "userSelectSeries") {
    activeSeries = selectedSeries.map((i) => props.series[i]);
  }

  let min = minBy(
    props.items,
    (r) => minBy(activeSeries, (s) => s.value(r))?.value
  )?.value;
  let max = maxBy(
    props.items,
    (r) => maxBy(activeSeries, (s) => s.value(r))?.value
  )?.value;

  if (min === undefined && max === undefined) {
    min = 0;
    max = 1;
  }

  const dataPoints = (s: SeriesDefintion<T>) => {
    const p = props.items
      .filter((r) => {
        const v = s.value(r);
        return v !== undefined && v !== null;
      })
      .map((r) => ({ x: r.measuredAt as any, y: s.value(r)! }));

    if (p.length === 0) {
      p.push({ x: props.items[0].measuredAt as any, y: 0 });
    }
    return p;
  };

  const setCrossHair = (d: LineSeriesPoint, title: string) => {
    const index = props.series.findIndex((os) => os.title === title);
    crossHairValues[index].p = d;
    setCrossHairValues([...crossHairValues]);
  };

  return (
    <div
      style={{ height: "calc(100% - 80px)" }}
      ref={chartRef}
      onMouseLeave={onMouseLeave}
      data-testid="chart-container"
    >
      {props.loading && <Spin className="centered" />}
      <div className="chart-container">
        <div className="line-chart">
          <XYPlot
            width={chartDimensions.width}
            height={chartDimensions.height}
            xDomain={[props.from, props.to]}
            margin={{ left: 75, right: 50, bottom: 72 }}
            onMouseDown={onZoomStart}
            onMouseMove={zoomFrom !== undefined ? onZoomMove : undefined}
            onMouseUp={onZoomEnd}
            xType="time"
          >
            {activeSeries.map((s, i) => (
              <LineSeries
                key={"line_" + i}
                data={props.loading ? [{ x: 1, y: 1 }] : dataPoints(s)}
                onNearestX={(d) => setCrossHair(d, s.title)}
              />
            ))}
            {props.atDate && (
              <LineSeries
                key="atdate"
                data={[
                  { x: props.atDate! as any, y: min! },
                  { x: new Date(props.atDate!.getTime() + 1) as any, y: max! },
                ]}
              />
            )}
            <XAxis tickLabelAngle={-90} />
            <YAxis />
            <DiscreteColorLegend
              className={
                "chart-legend" +
                (mode === "userSelectSeries"
                  ? " " + selectedSeries.map((i) => `selected-${i}`).join(" ")
                  : "")
              }
              items={[{ title: t("battery-voltage"), color: "#439198" }]}
              orientation="horizontal"
            />
            {!!chv.length && (
              <Crosshair values={chv.map((c) => c.point)}>
                <div className="chart-crosshair">
                  <h3>
                    {t("date")}:{" "}
                    {(
                      chv.find((p) => p?.point?.x)?.point?.x as any as Date
                    ).toLocaleString(
                      i18n.language === "de" ? "en-GB" : undefined
                    )}
                  </h3>
                  {chv.map((v) => (
                    <p key={"chv_" + v.seriesIndex}>
                      {props.series[v.seriesIndex].title}:{" "}
                      {v?.point?.y?.toLocaleString()}
                    </p>
                  ))}
                </div>
              </Crosshair>
            )}
            <ZoomOverlay from={zoomFrom} to={zoomTo} />
          </XYPlot>
        </div>
      </div>
    </div>
  );
}

function ZoomOverlay(props: { from?: number; to?: number }) {
  if (!props.from || !props.to) {
    return <></>;
  }

  return (
    <div
      style={{
        position: "absolute",
        pointerEvents: "none",
        top: 0,
        backgroundColor: "rgba(24, 144, 255, 0.1)",
        bottom: 0,
        left: props.from,
        width: props.to - props.from,
      }}
    ></div>
  );
}
