import React, { useCallback, useContext, useState } from "react";
import { Beacon, BeaconWithPosition } from "../../api";
import { ApiState, PagedApiState } from "../../api/useApiState";
import { BeaconDrawer } from "./BeaconDrawer";

export interface BeaconDrawerState {
    beacon: Beacon | undefined;
    show(beacon: Beacon): void;
    close(): void;
}

export const BeaconDrawerContext = React.createContext<BeaconDrawerState>({ beacon: undefined, show: () => { }, close: () => { } });

export function BeaconDrawerContextProvider(props: { children: React.ReactNode }) {
    const [beaconShownInDrawer, setBeaconShownInDrawer] = useState<Beacon>();
    const closeBeaconDrawer = useCallback(() => {
        setBeaconShownInDrawer(undefined);
    }, [setBeaconShownInDrawer]);

    return <BeaconDrawerContext.Provider value={{beacon: beaconShownInDrawer, show: setBeaconShownInDrawer, close: closeBeaconDrawer}}>
        {props.children}
        <BeaconDrawer beacon={beaconShownInDrawer} onClose={closeBeaconDrawer} />
    </BeaconDrawerContext.Provider>;
}

export function useUpdateDrawer(beaconsFromApi: PagedApiState<Beacon> | ApiState<BeaconWithPosition[] | undefined>){

    const beacons = Array.isArray(beaconsFromApi.value) ? beaconsFromApi.value : beaconsFromApi.value?.items;
    const drawer = useContext(BeaconDrawerContext);
    
    if(drawer.beacon && beacons?.length){
        const beacon = beacons.find(b => b.id === drawer.beacon?.id);
        if(beacon){
            drawer.show(beacon);
        }
    }
}