import { useCallback, useContext } from "react";
import {
  Beacon,
  BeaconError,
  BeaconTableSorting,
  PagedResponse,
  BeaconActiveErrorResponse,
  PutBeaconBySolutionOperatorRequest,
  GetBeaconConfigurationStateResponse,
  PutBeaconConfigurationRequest,
  BeaconWithPosition,
  FindBeaconInAreaRequest,
  BeaconLifecycle,
} from "./models";
import {
  useGetAuthorized,
  PagedApiState,
  usePagedApiState,
  ApiState,
  useApiState,
  ApiCall,
  useAuthorizedFetch,
} from "./useApiState";
import { FromToFilter, useFromToFilter, useRefreshCounter } from "./api.utils";
import { ErrorSeverity } from ".";
import {
  BeaconDrawerContext,
  useUpdateDrawer,
} from "../components/beacon-drawer/BeaconDrawerContext";
import { BorderVerticleOutlined } from "@ant-design/icons";

interface BeaconFilter {
  assigned: boolean;
  to: "ConstructionSite" | "Organization";
}

export function useBeacons(
  assignedToOrganization?: boolean,
  filterString?: string,
  onlyWithError?: ErrorSeverity,
  sorting?: BeaconTableSorting,
  pageSize?: number
): PagedApiState<Beacon> {
  let filter: BeaconFilter | undefined = undefined;

  if (assignedToOrganization !== undefined) {
    filter = { to: "Organization", assigned: assignedToOrganization };
  }

  let alreadyHasQueryParameter = !!filter;

  let query =
    filter === undefined ? "" : `?assignedTo${filter.to}=${filter.assigned}`;

  filterString = filterString?.trim();
  if (filterString) {
    const prefix = alreadyHasQueryParameter ? "&" : "?";
    query += prefix + "filter=" + filterString;
    alreadyHasQueryParameter = true;
  }

  if (onlyWithError !== undefined) {
    const prefix = alreadyHasQueryParameter ? "&" : "?";
    query += prefix + "hasErrors=true&severity=" + onlyWithError;
    alreadyHasQueryParameter = true;
  }

  if (sorting?.sortColumnId !== undefined) {
    const prefix = alreadyHasQueryParameter ? "&" : "?";
    query +=
      prefix + "sort=" + sorting.sortColumnId + "&order=" + sorting.sortOrderId;
    alreadyHasQueryParameter = true;
  }

  const beacons = usePagedApiState(
    useGetAuthorized<PagedResponse<Beacon>>(
      "beacons" + query,
      fixDatesOfPagedBeacons
    ),
    undefined,
    pageSize
  );
  useUpdateDrawer(beacons);
  return beacons;
}

export function useBeaconsInArea(
  request?: FindBeaconInAreaRequest
): ApiState<BeaconWithPosition[] | undefined> {
  const getBeaconsInArea = useGetBeaconsInArea();
  const get = useCallback(async () => {
    const beacons = await getBeaconsInArea(request);
    return beacons;
  }, [request || 1, getBeaconsInArea]);

  return useApiState(get, undefined, request || 1);
}

export function useGetBeaconsInArea(): ApiCall<
  FindBeaconInAreaRequest | undefined,
  BeaconWithPosition[] | undefined
> {
  const authorizedFetch = useAuthorizedFetch();
  return useCallback(
    async (request: FindBeaconInAreaRequest | undefined) => {
      if (!request) {
        return undefined;
      }
      const result = await authorizedFetch("beacons/in-area-query", {
        method: "post",
        body: JSON.stringify(request),
      });
      if (!result.ok) {
        throw result.status;
      }
      var beacons = (await result.json()) as BeaconWithPosition[];
      return fixDatesOfBeacons(beacons);
    },
    [authorizedFetch, fixDatesOfBeacons]
  );
}

export function useErrorHistory(
  beaconId: string,
  fromToFilter: FromToFilter,
  take?: number
): PagedApiState<BeaconError> {
  const refreshCounter = useRefreshCounter();
  const fixDates = useCallback((e: PagedResponse<BeaconError>) => {
    e.items?.forEach((err) => {
      err.measuredAt = new Date(err.measuredAt);
    });
    return e;
  }, []);
  const appendDateFilter = useFromToFilter(fromToFilter);
  const get = useGetAuthorized<PagedResponse<BeaconError>>(
    `beacons/${beaconId}/history?messageType=ERR${take ? "&take=" + take : ""}`,
    fixDates,
    appendDateFilter
  );
  return usePagedApiState(get, refreshCounter);
}

export function useLifecycleHistory(
  beaconId: string,
  fromToFilter: FromToFilter,
  take?: number
): PagedApiState<BeaconLifecycle> {
  const refreshCounter = useRefreshCounter();
  const fixDates = useCallback((e: PagedResponse<BeaconLifecycle>) => {
    e.items?.forEach((err) => {
      err.measuredAt = new Date(err.measuredAt);
    });
    return e;
  }, []);
  const appendDateFilter = useFromToFilter(fromToFilter);
  const get = useGetAuthorized<PagedResponse<BeaconLifecycle>>(
    `beacons/${beaconId}/history?messageType=LIFE${
      take ? "&take=" + take : ""
    }`,
    fixDates,
    appendDateFilter
  );
  return usePagedApiState(get, refreshCounter);
}

export function useActiveErrors(
  beaconId: string
): ApiState<BeaconActiveErrorResponse[] | undefined> {
  const refreshCounter = useRefreshCounter();
  const fixDates = useCallback((e: BeaconActiveErrorResponse[]) => {
    e.forEach((err) => {
      err.startedAt = new Date(err.startedAt);
      if (err.hasBeenReadAt) {
        err.hasBeenReadAt = new Date(err.hasBeenReadAt);
      }
    });
    return e;
  }, []);
  return useApiState(
    useGetAuthorized<BeaconActiveErrorResponse[]>(
      `beacons/${beaconId}/active-errors?resolved=false`,
      fixDates
    ),
    undefined,
    refreshCounter
  );
}

export function useBeacon(beaconId: string): ApiState<Beacon | undefined> {
  return useApiState(
    useGetAuthorized<Beacon>(`beacons/${beaconId}`, fixDatesOfBeacon),
    undefined
  );
}

export function useUpdateBeacon(
  beaconId: string
): ApiCall<PutBeaconBySolutionOperatorRequest, Beacon> {
  const authorizedFetch = useAuthorizedFetch();
  return async (patch: PutBeaconBySolutionOperatorRequest) => {
    const result = await authorizedFetch(`beacons/${beaconId}`, {
      method: "put",
      body: JSON.stringify(patch),
    });
    if (!result.ok) {
      throw result.status;
    }
    return (await result.json()) as Beacon;
  };
}

export function useBeaconConfiguration(
  beaconId: string,
  reloadToken: number
): ApiState<GetBeaconConfigurationStateResponse | undefined> {
  return useApiState(
    useGetAuthorized<GetBeaconConfigurationStateResponse>(
      `beacons/${beaconId}/configuration`
    ),
    undefined,
    reloadToken
  );
}

export function useConfigureBeacon(): ApiCall<
  { beaconId: string; configuration: PutBeaconConfigurationRequest },
  void
> {
  const authorizedFetch = useAuthorizedFetch();
  return async (input: {
    beaconId: string;
    configuration: PutBeaconConfigurationRequest;
  }) => {
    const result = await authorizedFetch(
      `beacons/${input.beaconId}/configuration`,
      { method: "put", body: JSON.stringify(input.configuration) }
    );
    if (!result.ok) {
      throw result.status;
    }
  };
}

export function useMarkErrorAsRead(): ApiCall<
  { beaconId: string; code: string } | ErrorSeverity,
  void
> {
  const authorizedFetch = useAuthorizedFetch();
  return async (filter: { beaconId: string; code: string } | ErrorSeverity) => {
    const url =
      typeof filter === "number"
        ? `active-errors/${filter}`
        : `beacons/${filter.beaconId}/active-errors/${filter.code}`;

    const result = await authorizedFetch(url, { method: "post" });
    if (!result.ok) {
      throw result.status;
    }
  };
}

export function useDeleteError(): ApiCall<
  { beaconId: string; code: string } | ErrorSeverity,
  void
> {
  const authorizedFetch = useAuthorizedFetch();
  return async (filter: { beaconId: string; code: string } | ErrorSeverity) => {
    const url =
      typeof filter === "number"
        ? `active-errors/${filter}`
        : `beacons/${filter.beaconId}/active-errors/${filter.code}`;

    const result = await authorizedFetch(url, { method: "delete" });
    if (!result.ok) {
      throw result.status;
    }
  };
}

export function fixDatesOfBeacon(
  beacon: BeaconWithPosition
): BeaconWithPosition;
export function fixDatesOfBeacon(beacon: Beacon): Beacon;
export function fixDatesOfBeacon(beacon: Beacon | BeaconWithPosition) {
  if (beacon.lastMessageReceivedAt) {
    beacon.lastMessageReceivedAt = new Date(beacon.lastMessageReceivedAt);
  }

  if (beacon.onOffSwitch?.lastReportedAt) {
    beacon.onOffSwitch.lastReportedAt = new Date(
      beacon.onOffSwitch.lastReportedAt
    );
  }

  if (beacon.environment?.measuredAt) {
    beacon.environment.measuredAt = new Date(beacon.environment.measuredAt);
  }

  const withPosition = beacon as BeaconWithPosition;
  if (withPosition?.position?.measuredAt) {
    withPosition.position.measuredAt = new Date(
      withPosition.position.measuredAt
    );
  }

  if (beacon.activeErrors) {
    beacon.activeErrors.forEach((b) => {
      b.startedAt = new Date(b.startedAt);
      if (b.hasBeenReadAt) {
        b.hasBeenReadAt = new Date(b.hasBeenReadAt);
      }
    });
  }

  return beacon;
}

export function fixDatesOfPagedBeacons(
  beacons: PagedResponse<BeaconWithPosition>
): PagedResponse<BeaconWithPosition>;
export function fixDatesOfPagedBeacons(
  beacons: PagedResponse<Beacon>
): PagedResponse<Beacon>;
export function fixDatesOfPagedBeacons(
  beacons: PagedResponse<Beacon> | PagedResponse<BeaconWithPosition>
) {
  beacons.items.forEach(fixDatesOfBeacon);
  return beacons;
}

export function fixDatesOfBeacons(
  beacons: BeaconWithPosition[]
): BeaconWithPosition[];
export function fixDatesOfBeacons(beacons: Beacon[]): Beacon[];
export function fixDatesOfBeacons(beacons: Beacon[] | BeaconWithPosition[]) {
  beacons.forEach(fixDatesOfBeacon);
  return beacons;
}
