import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Divider, Dropdown, Popover } from "antd";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
    Beacon,
    BeaconActiveError,
    BeaconSortingColumn,
    BeaconSortingOrder,
    BeaconTableSorting,
    ErrorSeverity,
    useBeacons,
    useDeleteError,
    useErrorTranslation,
    useMarkErrorAsRead,
} from "../api";
import { AuthContext } from "../contexts/authContext";
import { DataRefreshContext } from "../contexts/dataRefreshContext";
import { count } from "../util";
import { ActionsIcon } from "./ActionsIcon";
import { BeaconDrawerContext } from "./beacon-drawer/BeaconDrawerContext";
import { BeaconErrorStatus } from "./BeaconErrorStatus";

interface BeaconsErrorsProps {
    severity: ErrorSeverity;
    assignedToOrganization?: boolean;
    filter?: string;
    style?: React.CSSProperties;
}

function noop<T>(e: React.MouseEvent<T>) {
    e.stopPropagation();
};

export function BeaconErrorActionButton(props: { beaconId?: string; error?: BeaconActiveError, severity?: ErrorSeverity }) {
    const { t } = useTranslation();
    const markErrorAsRead = useMarkErrorAsRead();
    const deleteError = useDeleteError();
    const allSuffix = props.error ? '' : '-all';
    const dataRefresh = useContext(DataRefreshContext);
    const isRoadSafetyManager = useContext(AuthContext).currentUser?.accessToken?.hasRole('road-safety-manager');

    const onRead = useCallback(async () => {
        if (props.error && props.beaconId) {
            await markErrorAsRead({ beaconId: props.beaconId, code: props.error.code });
        } else if (props.severity !== undefined) {
            await markErrorAsRead(props.severity);
        }
        dataRefresh.refresh(true);
    }, [props.error, markErrorAsRead, dataRefresh]);

    const onDelete = useCallback(async () => {
        if (props.error && props.beaconId) {
            await deleteError({ beaconId: props.beaconId, code: props.error.code });
        } else if (props.severity !== undefined) {
            await deleteError(props.severity);
        }
        dataRefresh.refresh(true);
    }, [props.error, deleteError, dataRefresh]);

    if (!isRoadSafetyManager) {
        return <></>;
    }

    return <Popover overlayStyle={{ zIndex: 10000 }} placement="right" content={<div onClick={noop} style={{ display: 'grid', gridTemplateRows: 'auto auto' }}>
        <Button onClick={onRead} disabled={!!props.error?.hasBeenRead} className="icon-left-text-right" type="primary" icon={<CheckOutlined />}>{t('error:mark-as-read' + allSuffix)}</Button>
        <Button danger onClick={onDelete} className="icon-left-text-right" type="primary" icon={<DeleteOutlined />} style={{ marginTop: 8 }}>{t('error:delete' + allSuffix)}</Button>
    </div>}>
        <Button style={{ alignSelf: 'center', marginRight: 8 }}><ActionsIcon /></Button>
    </Popover>
}

function BeaconErrorContent(props: { beacon: Beacon; error: BeaconActiveError, onClick: () => void }) {
    const errorTranslation = useErrorTranslation();
    const fontWeight: React.CSSProperties['fontWeight'] = props.error.hasBeenRead ? 'normal' : 'bold';

    return <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', columnGap: '1em' }}>
        <div style={{ cursor: 'pointer', fontWeight }} onClick={props.onClick}>
            <div style={{ color: 'black' }}>{props.beacon.serial || props.beacon.id}</div>
            <div style={{ color: 'gray' }}>{errorTranslation(props.error.code, props.error.severity)}</div>
        </div>
        <BeaconErrorActionButton error={props.error} beaconId={props.beacon.id} />
    </div>;
}

function BeaconDetailedErrorContent(props: { beacon: Beacon; error: BeaconActiveError }) {
    const { t } = useTranslation();
    const errorTranslation = useErrorTranslation();
    const fontWeight: React.CSSProperties['fontWeight'] = props.error.hasBeenRead ? 'normal' : 'bold';

    return <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr auto', columnGap: '1em' }}>
        <BeaconErrorStatus severity={props.error.severity} style={{ marginTop: 10, marginRight: -4 }} />
        <div style={{ cursor: 'pointer', fontWeight }}>
            <div style={{ color: 'black' }}>{errorTranslation(props.error.code, props.error.severity)}</div>
            <div style={{ color: 'gray' }}>{t('error:active-since', { since: props.error.startedAt.toLocaleString() })}</div>
        </div>
        <BeaconErrorActionButton error={props.error} beaconId={props.beacon.id} />
    </div>;
}

export function BeaconError(props: { beacon: Beacon; error: BeaconActiveError }) {
    const drawer = useContext(BeaconDrawerContext);
    const dataRefresh = useContext(DataRefreshContext);
    const isRoadSafetyManager = useContext(AuthContext).currentUser?.accessToken?.hasRole('road-safety-manager');
    const markErrorAsRead = useMarkErrorAsRead();

    const open = useCallback(async () => {
        if (!props.error.hasBeenRead) {
            if (isRoadSafetyManager) {
                await markErrorAsRead({ beaconId: props.beacon.id, code: props.error.code });
                dataRefresh.refresh(true);
            }
        }
        drawer.show(props.beacon);
    }, [drawer, props.beacon, props.error, dataRefresh, markErrorAsRead, isRoadSafetyManager]);


    return <>
        <Divider type="horizontal" style={{ margin: '8px 0' }} />
        <BeaconErrorContent beacon={props.beacon} error={props.error} onClick={open} />
    </>;
}

export function BeaconErrors(props: { beacon: Beacon }) {
    const { beacon } = props;

    if (!beacon.activeErrors?.length) {
        return <></>;
    }

    return <div style={{ display: 'flex', columnGap: 8 }}>
        {beacon.activeErrors.map(e => <div style={{ border: '1px solid #CCCCCC', borderRadius: 6, padding: '4px 0 4px 10px' }}>
            <BeaconDetailedErrorContent beacon={beacon} error={e} />
        </div>)}
    </div>
}

export function BeaconsErrors(props: BeaconsErrorsProps) {
    const { t } = useTranslation();

    const errorBeaconSorting: BeaconTableSorting = {
      sortColumnId: BeaconSortingColumn.LastMessageReceivedAt,
      sortOrderId: BeaconSortingOrder.Descending,
    }; 

    const beaconsWithError = useBeacons(
      props.assignedToOrganization,
      props.filter,
      props.severity,
      errorBeaconSorting,
      1000
    );

    if (!beaconsWithError?.value?.totalCount) {
        return <></>;
    }

    const errors = beaconsWithError.value.items.flatMap(b => (b.activeErrors || []).map(e => ({ e, b })));
    const unreadErrors = count(errors, e => !e.e.hasBeenRead);

    return <Dropdown  data-testid="beacon-errors" overlay={<Card style={{ padding: '1em', backgroundColor: 'white' }}>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', columnGap: '1em' }}>
            <span style={{ fontWeight: 'bold' }}>{t('error:alerts')}</span>
            <BeaconErrorActionButton severity={props.severity} />
        </div>
        <div style={{ maxHeight: window.innerHeight * 0.7, overflow: 'auto' }}>
            {errors.map(e => <BeaconError key={e.b.id + '_' + e.e.code} beacon={e.b} error={e.e} />)}
        </div>
    </Card>}>
        <Badge count={unreadErrors}>
            <Button style={props.style} icon={<BeaconErrorStatus severity={props.severity} />} />
        </Badge>
    </Dropdown>;

}