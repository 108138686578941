import { useCallback, useContext, useState } from 'react';
import './App.css';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import Auth from '@aws-amplify/auth';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import { routes } from './routes';
import { NavigationMenu } from './components/NavigationMenu';
import { AuthContext, CurrentUser, AccessToken } from './contexts/authContext';
import { DataAutoRefreshContext, DataRefreshContext } from './contexts/dataRefreshContext';
import { UserMenu } from './components/UserMenu';
import { Environment } from './components/Environment';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSetting } from './util';
import { useMyOrganization } from './api';
import { NoOrganization } from './screens/no-role/NoRole';
import { BeaconDrawerContext, BeaconDrawerContextProvider } from './components/beacon-drawer/BeaconDrawerContext';

function VerifyRoadSafetyManagerHasAnOrganization(props: { children: React.ReactNode }) {
  const authContext = useContext(AuthContext);
  const isRoadSafetyManager = authContext.currentUser?.accessToken?.hasRole('road-safety-manager');
  const myOrg = useMyOrganization();
  return <>{isRoadSafetyManager && !myOrg.loading && !myOrg.value?.id ? <NoOrganization /> : props.children}</>;
}

function App() {

  const { t } = useTranslation();
  const [user, setUser] = useState<CurrentUser | undefined>(undefined);
  const [refreshCounter, setRefreshCounter] = useState(1);

  const [autoRefreshInterval, setAutoRefreshInterval] = useSetting('auto-refresh-interval', 60000);
  const [autoRefreshDisabled, setAutoRefreshDisabled] = useState(false);
  const enableAutoRefresh = useCallback(() => setAutoRefreshDisabled(false), [setAutoRefreshDisabled]);
  const disableAutoRefresh = useCallback(() => setAutoRefreshDisabled(true), [setAutoRefreshDisabled]);

  const handleAuthStateChange = async (a: any, c?: any) => {
    if (c?.attributes?.email) {
      const session = await Auth.currentSession();
      const accessToken = new AccessToken(session.getAccessToken());
      setUser({
        email: c?.attributes?.email,
        accessToken
      });
    }
    else {
      setUser(undefined);
    }
  };

  const onRefresh = (againWithDelay?: boolean) => {
    setRefreshCounter(c => c + 1);
    if (againWithDelay) {
      setTimeout(() => setRefreshCounter(c => c + 1), 2000);
    }
  };

  const onRefreshClick = () => { onRefresh(); };

  return <Environment>
    <AmplifyAuthenticator handleAuthStateChange={handleAuthStateChange}>
      <AuthContext.Provider value={{ currentUser: user }}>
        <DataRefreshContext.Provider value={{ refreshCounter, refresh: onRefresh }}>
          <DataAutoRefreshContext.Provider value={{ interval: autoRefreshInterval, setInterval: setAutoRefreshInterval, disabled: autoRefreshDisabled, disable: disableAutoRefresh, enable: enableAutoRefresh }}>
            {!!user &&
              <VerifyRoadSafetyManagerHasAnOrganization>
                <BrowserRouter>
                  <BeaconDrawerContextProvider>
                    <Layout style={{ height: "100vh" }}>
                      <Layout.Header>
                        <div className="app-cm1-logo"></div>
                        <div style={{ float: 'right', marginRight: -20, userSelect: 'none' }}>
                          <UserMenu />
                        </div>
                        <div style={{ float: 'right', marginRight: '1em', transform: 'translateY(4px)', userSelect: 'none' }}>
                          <ReloadOutlined title={t('reload')} className="clickable" onClick={onRefreshClick} />
                        </div>
                        <NavigationMenu />
                      </Layout.Header>
                      <Layout.Content style={{ padding: 10 }}>
                        <Switch>
                          {routes(user).map((route, index) => (
                            <Route
                              key={index}
                              path={route.path}
                              exact
                              children={<route.component />}
                            />
                          ))}
                          <Redirect key="redirect" to={user.accessToken.hasRole('road-safety-manager') ? 'construction-projects' : 'beacons'} />
                        </Switch>
                      </Layout.Content>
                    </Layout>
                  </BeaconDrawerContextProvider>
                </BrowserRouter>
              </VerifyRoadSafetyManagerHasAnOrganization>
            }
          </DataAutoRefreshContext.Provider>
        </DataRefreshContext.Provider>
      </AuthContext.Provider>
    </AmplifyAuthenticator>
  </Environment>;
}

export default App;
