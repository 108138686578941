import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { BeaconActiveError, ErrorSeverity, getErrorCode, useErrorTranslation } from "../api";

interface BeaconActiveErrorsTooltipProps {
    errors: BeaconActiveError[];
}

export function ActiveErrorsLabel(props: { errors: BeaconActiveError[] }) {
    const { t } = useTranslation();
    const errors = props.errors.filter(e => e.severity !== ErrorSeverity.Warning);
    const warnings = props.errors.filter(e => e.severity === ErrorSeverity.Warning);

    const errorLabel = errors.length ? t('beacon:active-errors-tooltip', { count: errors.length }) : undefined;
    const warningLabel = warnings.length ? t('beacon:active-warnings-tooltip', { count: warnings.length }) : undefined;

    const label = `${errorLabel ?? ''}${(errorLabel && warningLabel ? ', ' : '')}${warningLabel ?? ''}`;
    return <>{label}</>;
}

export function BeaconActiveErrorsTooltip(props: BeaconActiveErrorsTooltipProps) {

    const errorTranslation = useErrorTranslation();

    const errors = props.errors.filter(e => e.severity !== ErrorSeverity.Warning);
    const warnings = props.errors.filter(e => e.severity === ErrorSeverity.Warning);

    return <div>
        <span style={{ fontWeight: 'bold' }}><ActiveErrorsLabel errors={props.errors} /></span>
        {!!errors.length && <>
            <Divider style={{ margin: '0.25em  0', backgroundColor: 'white' }} type="horizontal" />
            {errors.map((e, i) => <div key={'error_' + i}>{errorTranslation(e.code, e.severity)}</div>)}</>}
        {!!warnings.length && <>
            <Divider style={{ margin: '0.25em  0', backgroundColor: 'white' }} type="horizontal" />
            {warnings.map((e, i) => <div key={'error_' + i}>{errorTranslation(e.code, e.severity)}</div>)}</>}
    </div>;
}