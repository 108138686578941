import { CheckCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Card, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import {
  Beacon,
  BeaconError,
  useActiveErrors,
  useErrorTranslation,
} from "../../../api";
import { PagedApiState } from "../../../api/useApiState";
import "../../../styles/card.scss";
import { ActiveErrorsLabel } from "../../BeaconActiveErrorsTooltip";
import { BeaconErrorStatus } from "../../BeaconErrorStatus";
import { BeaconErrorTable, BeaconErrorTableColumns } from "./BeaconErrorTable";

interface BeaconErrorDrawerProps {
  beaconId: Beacon["id"];
  errorHistory: PagedApiState<BeaconError>;
  errorDrawerVisible: boolean;
  onCloseDrawer: (visible: boolean) => void;
}

export function BeaconErrorDrawer({
  beaconId,
  errorHistory,
  errorDrawerVisible,
  onCloseDrawer,
}: BeaconErrorDrawerProps) {
  const { t } = useTranslation();
  const errorTranslation = useErrorTranslation();

  const activeErrors = useActiveErrors(beaconId);
  const activeErrorCount = activeErrors?.value?.length || 0;

  const closeDrawer = () => {
    onCloseDrawer(false);
  };

  return (
    <Drawer
      aria-label="beacon-error-drawer"
      bodyStyle={{ margin: "1.5rem", padding: "0px" }}
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{t("beacon:error-details")}</span>
          <Button
            type="text"
            className="close-card-drawer-button"
            icon={<CloseOutlined />}
            onClick={closeDrawer}
          />
        </div>
      }
      placement="right"
      closable={false}
      onClose={closeDrawer}
      visible={errorDrawerVisible}
      width={1200}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          gridColumnGap: "1em",
          gridRowGap: "1em",
        }}
      >
        <Card style={{ display: "flex", alignItems: "center" }}>
          {activeErrorCount === 0 ? (
            <div style={{ textAlign: "center" }}>
              <CheckCircleOutlined
                style={{ color: "green", fontSize: "3em" }}
              />
              <div>{t("error:no-active-errors")}</div>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <BeaconErrorStatus
                errors={activeErrors.value}
                style={{ fontSize: "3em" }}
              />
              <div>
                <ActiveErrorsLabel errors={activeErrors.value!} />
              </div>
            </div>
          )}
        </Card>
        {activeErrorCount > 0 && (
          <Card style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                gridColumnGap: "1em",
                gridRowGap: "1em",
              }}
            >
              {activeErrors.value!.map((e) => (
                <>
                  <span style={{ fontWeight: "bold" }}>
                    {errorTranslation(e.code, e.severity)}
                  </span>
                  <span>
                    {t("error:active-since", {
                      since: e.startedAt.toLocaleString(),
                    })}
                  </span>
                </>
              ))}
            </div>
          </Card>
        )}
      </div>
      <br />
      <BeaconErrorTable
        errorHistory={errorHistory}
        visibleColumns={[
          BeaconErrorTableColumns.Status,
          BeaconErrorTableColumns.ErrorText,
          BeaconErrorTableColumns.Severity,
          BeaconErrorTableColumns.CreatedAt,
          BeaconErrorTableColumns.ErrorCode,
        ]}
      />
    </Drawer>
  );
}
