import { useCallback, useContext } from 'react';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../contexts/authContext';
import { Button, Dropdown, Menu, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { DataAutoRefreshContext } from '../contexts/dataRefreshContext';

export function UserMenu() {

  const authContext = useContext(AuthContext);
  const { interval, setInterval } = useContext(DataAutoRefreshContext);
  const { i18n, ready, t } = useTranslation();

  const german = useCallback(() => { i18n.changeLanguage('de') }, [i18n]);
  const english = useCallback(() => { i18n.changeLanguage('en') }, [i18n]);

  if (!authContext.currentUser) {
    return <></>;
  }

  return <Dropdown overlay={<Menu >
    {ready && <div className="multi-button-container" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '5px 12px' }}>
      <Button onClick={german} type={i18n.language === 'de' || i18n.language?.startsWith('de-')  ? 'primary' : 'default'}>Deutsch</Button>
      <Button onClick={english} type={i18n.language === 'en' || i18n.language?.startsWith('en-')  ? 'primary' : 'default'}>English</Button>
    </div>}
    <Menu.Divider />
    <div style={{ padding: '5px 12px', width: '100%' }}>
      <div>{t('reload-rate')}</div>
      <Select value={interval} onChange={setInterval} style={{ width: '100%' }}>
        <Select.Option value={1000}>{t('reload-rate-1000')}</Select.Option>
        <Select.Option value={10000}>{t('reload-rate-10000')}</Select.Option>
        <Select.Option value={30000}>{t('reload-rate-30000')}</Select.Option>
        <Select.Option value={60000}>{t('reload-rate-60000')}</Select.Option>
        <Select.Option value={3600000}>{t('reload-rate-3600000')}</Select.Option>
      </Select>
    </div>
    <Menu.Divider />
    <Menu.Item>
      <AmplifySignOut />
    </Menu.Item>

  </Menu>}>
    <a href="/" className="ant3600000-dropdown-link" onClick={e => e.preventDefault()} style={{ color: 'white' }}>
      {authContext.currentUser.email} <DownOutlined />
    </a>
  </Dropdown>;
}