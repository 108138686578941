import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { AmplifyLoadingSpinner } from "@aws-amplify/ui-react";
import { Button, Checkbox, Input, Spin, Table } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ColumnType } from "antd/lib/table";
import { SorterResult, TableLocale } from "antd/lib/table/interface";
import { TFunction } from "i18next";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useBeacons } from "../api";
import {
  Beacon,
  BeaconSortingColumn,
  BeaconSortingOrder,
  BeaconTableSorting,
  ConstructionSite,
  ErrorSeverity,
} from "../api/models";
import { ApiState, PagedApiState } from "../api/useApiState";
import { AuthContext } from "../contexts/authContext";
import { AssignBeaconsModal } from "../screens/construction-project/AssignBeaconsModal";
import {
  groupBy,
  toAntdPagination,
  toDictionary,
  useDebouncedFilter,
  useFilterValueChangedHandler,
  useTransformedState,
} from "../util";
import { Badge } from "./Badge";
import { BeaconConfigurationModal } from "./beacon-configuration/BeaconConfigurationModal";
import { BeaconEnableDisableButton } from "./BeaconEnableDisableButton";
import { BeaconsErrors } from "./BeaconsErrors";
import { BeaconStatus } from "./BeaconStatus";
import { ConstructionSiteActionMenu } from "./ConstructionSiteActionMenu";
import { CopyableText } from "./copyable-text";
import { MapPosition } from "./Map";

interface BeaconTableProps {
  size?: "small" | "middle" | "large";
  selectedBeaconIds?: string[];
  setSelectedBeaconIds?(ids: string[]): void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  hideFooter?: boolean;
  additionalColumns?: ColumnType<Beacon>[];
  showConfigureButtons?: boolean;
  noData?: React.ReactNode | (() => React.ReactNode);
  loading?: boolean;
  sorting?: BeaconTableSorting;
  onSort?(
    property: keyof Beacon,
    direction: "descend" | "ascend" | undefined | null
  ): void;
  hideIccid?: boolean;
  context?: string;
}

interface BeaconTableWithServerSidePaginationProps extends BeaconTableProps {
  pagination: "server";
  beacons: PagedApiState<Beacon>;
}

interface BeaconTableWithClientSidePaginationProps extends BeaconTableProps {
  pagination: "client";
  beacons: ApiState<Beacon[] | undefined>;
}

function BeaconTable(
  props:
    | BeaconTableWithServerSidePaginationProps
    | BeaconTableWithClientSidePaginationProps
) {
  const { selectedBeaconIds, setSelectedBeaconIds } = props;
  const { t } = useTranslation();

  const dataIndexSerial: keyof Pick<Beacon, "serial"> = "serial";
  const dataIndexStatus: keyof Pick<Beacon, "onOffSwitch"> = "onOffSwitch";
  const dataIndexConstructionProjectName: keyof Pick<
    Beacon,
    "constructionProjectName"
  > = "constructionProjectName";
  const dataIndexConstructionSiteName: keyof Pick<
    Beacon,
    "constructionSiteName"
  > = "constructionSiteName";
  const dataIndexOrganizationName: keyof Pick<Beacon, "organizationName"> =
    "organizationName";
  const dataIndexLastMessageReceivedAt: keyof Pick<
    Beacon,
    "lastMessageReceivedAt"
  > = "lastMessageReceivedAt";

  const authContext = useContext(AuthContext);
  const currentUserIsSolutionOperator =
    authContext.currentUser?.accessToken?.hasRole("solution-operator");

  const beaconArray =
    props.pagination === "server"
      ? props.beacons.value?.items
      : props.beacons.value;
  const pagination =
    props.pagination === "server"
      ? toAntdPagination(props.beacons, false)
      : { hideOnSinglePage: true };
  let { hideFooter } = props;
  hideFooter = hideFooter || (!props.footer && !setSelectedBeaconIds);

  const onChangeRowSelection = useCallback(
    (selectedRowKeys: React.Key[]) => {
      const isOnCurrentPage = beaconArray!.reduce((is, b) => {
        is[b.id] = true;
        return is;
      }, {} as { [id: string]: boolean });

      const selectedBeaconIdsOnCurrentPage = selectedRowKeys.filter(
        (r) => isOnCurrentPage[r]
      ) as string[];

      setSelectedBeaconIds!([
        ...selectedBeaconIds!.filter((b) => !isOnCurrentPage[b]),
        ...selectedBeaconIdsOnCurrentPage,
      ]);
    },
    [setSelectedBeaconIds, selectedBeaconIds, beaconArray]
  );

  const onSelectAll = useCallback(
    (e: CheckboxChangeEvent) => {
      if (!beaconArray || !setSelectedBeaconIds) {
        return;
      }

      if (!e.target.checked) {
        setSelectedBeaconIds([]);
      } else {
        setSelectedBeaconIds(beaconArray.map((b) => b.id));
      }
    },
    [setSelectedBeaconIds, beaconArray]
  );

  const refresh = props.beacons.refresh;
  const renderEnableDisableButton = useCallback(
    (b: Beacon) => <BeaconEnableDisableButton beacon={b} onUpdated={refresh} />,
    [refresh]
  );
  const renderConfigurationButton = useCallback(
    (b: Beacon) => <BeaconConfigurationModal beacon={b} />,
    [refresh]
  );
  const actions: ((b: Beacon) => JSX.Element)[] = [];
  if (props.showConfigureButtons) {
    if (currentUserIsSolutionOperator) {
      actions.push(renderEnableDisableButton);
      actions.push(renderConfigurationButton);
    }
  }

  const tableLocale: TableLocale | undefined = props.noData
    ? { emptyText: props.noData }
    : undefined;

  const [sortedInfo, setSortedInfo] = useState<{
    columnKey?: keyof Beacon;
    order?: "ascend" | "descend" | null;
  }>({});

  const onSortingChange = useCallback(
    (_1, _2, sorter: SorterResult<Beacon> | SorterResult<Beacon>[]) => {
      if (!props.onSort) {
        return;
      }

      const s = Array.isArray(sorter) ? sorter[0] : sorter;

      props.onSort(s.columnKey as keyof Beacon, s.order);

      setSortedInfo({ columnKey: s.columnKey as keyof Beacon, order: s.order });
    },
    [props.onSort]
  );

  /*  
    Need to ensure that the page is rendered before we allow a cell to be clicked. 
    if this is not done, then react will perform a click event on each row on render. 
    This is an expected behavior of ant.design tables (table used in this component) as
    confirmed through researching the issue.
  */
  let isPageRendered = useRef<boolean>(false);

  useEffect(() => {
    if (!isPageRendered.current) {
      isPageRendered.current = true;
    }
  }, []);

  const history = useHistory();

  const onSerialCellClicked = (beacon: Beacon) => {
    history.push(`/beacons/${beacon.id}`);
  };

  const onConstructionProjectCellClicked = (beacon: Beacon) => {
    if (beacon.constructionProjectId !== null) {
      history.push(`/construction-projects/${beacon.constructionProjectId}`);
    }
  };

  const onOrganizationCellClicked = (beacon: Beacon) => {
    if (beacon.organizationId !== null) {
      history.push(`/organizations/${beacon.organizationId}`);
    }
  };

  return !!props.beacons.loading && !beaconArray ? (
    <AmplifyLoadingSpinner />
  ) : (
    <>
      <Table
        sticky
        className={props.header ? "with-custom-title" : undefined}
        title={props.header ? () => props.header : undefined}
        locale={tableLocale}
        size={props.size || "middle"}
        dataSource={beaconArray || []}
        loading={props.loading}
        rowSelection={
          setSelectedBeaconIds
            ? {
                type: "checkbox",
                onChange: onChangeRowSelection,
                selectedRowKeys: selectedBeaconIds,
                columnTitle:
                  props.pagination === "client" ? (
                    <Checkbox
                      onChange={onSelectAll}
                      checked={
                        !!selectedBeaconIds?.length &&
                        selectedBeaconIds?.length === beaconArray?.length
                      }
                    />
                  ) : undefined,
              }
            : undefined
        }
        rowKey="id"
        columns={[
          {
            title: t("beacon:serial"),
            render: (b: Beacon) => b.serial || b.id,
            sorter: props.context === "/beacons" ? true : false,
            sortOrder:
              sortedInfo.columnKey === "serial" ? sortedInfo.order : null,
            key: dataIndexSerial,
            onCell: (beacon: Beacon) => ({
              onClick: () => {
                if (isPageRendered.current) {
                  onSerialCellClicked(beacon);
                }
              },
              style: { cursor: "pointer" },
            }),
          },
          {
            title: t("beacon:status"),
            render: (b: Beacon) => <BeaconStatus beacon={b} />,
            sorter: props.context === "/beacons" ? true : false,
            sortOrder:
              sortedInfo.columnKey === "onOffSwitch" ? sortedInfo.order : null,
            key: dataIndexStatus,
          },
          ...(currentUserIsSolutionOperator && !props.hideIccid
            ? [
                {
                  title: t("beacon:iccid"),
                  render: (b: Beacon) => <CopyableText text={b.iccid} />,
                },
              ]
            : []),
          ...(props.context === "/beacons"
            ? [
                {
                  title: t("translations:construction-project"),
                  render: (b: Beacon) =>
                    !!b.constructionProjectName
                      ? `${b.constructionProjectName}${
                          b.constructionProjectCreatedAutomatically
                            ? ` (${t(
                                "construction-project:created-automatically"
                              )})`
                            : ""
                        }`
                      : "",
                  sorter: props.context === "/beacons" ? true : false,
                  sortOrder:
                    sortedInfo.columnKey === "constructionProjectName"
                      ? sortedInfo.order
                      : null,
                  key: dataIndexConstructionProjectName,
                  onCell: (beacon: Beacon) => ({
                    onClick: () => {
                      if (isPageRendered.current) {
                        onConstructionProjectCellClicked(beacon);
                      }
                    },
                    style: { cursor: "pointer" },
                  }),
                },
                {
                  title: t("translations:construction-site"),
                  render: (b: Beacon) => b.constructionSiteName,
                  sorter: props.context === "/beacons" ? true : false,
                  sortOrder:
                    sortedInfo.columnKey === "constructionSiteName"
                      ? sortedInfo.order
                      : null,
                  key: dataIndexConstructionSiteName,
                },
                ...(currentUserIsSolutionOperator
                  ? [
                      {
                        title: t("translations:organization"),
                        render: (b: Beacon) => b.organizationName,
                        sorter: props.context === "/beacons" ? true : false,
                        sortOrder:
                          sortedInfo.columnKey === "organizationName"
                            ? sortedInfo.order
                            : null,
                        key: dataIndexOrganizationName,
                        onCell: (beacon: Beacon) => ({
                          onClick: () => {
                            if (isPageRendered.current) {
                              onOrganizationCellClicked(beacon);
                            }
                          },
                          style: { cursor: "pointer" },
                        }),
                      },
                    ]
                  : []),
                {
                  title: t("beacon:last-message-received-at"),
                  render: (b: Beacon) =>
                    b.lastMessageReceivedAt?.toLocaleString(),
                  sorter: props.context === "/beacons" ? true : false,
                  sortOrder:
                    sortedInfo.columnKey === "lastMessageReceivedAt"
                      ? sortedInfo.order
                      : null,
                  key: dataIndexLastMessageReceivedAt,
                },
              ]
            : []),
          ...(props.additionalColumns || []),
          ...(currentUserIsSolutionOperator
            ? [
                {
                  render: (b: Beacon) => renderConfigurationButton(b),
                  width: "95px",
                },
              ]
            : []),
        ]}
        pagination={pagination}
        onChange={onSortingChange}
        footer={footer(
          hideFooter,
          selectedBeaconIds,
          setSelectedBeaconIds,
          t,
          props
        )}
      />
    </>
  );
}

interface ConstructionProjectBeaconsTableProps {
  constructionProjectId: string;
  beacons: ApiState<Beacon[] | undefined>;
  sites: ApiState<ConstructionSite[] | undefined>;
  selectedBeaconIds?: string[];
  setSelectedBeaconIds?(ids: string[]): void;
  footer?: React.ReactNode;
  hideFooter?: boolean;
  defaultMapPositionForBeaconAssignments?: MapPosition;
  startEditingConstructionSite(constructionSite: ConstructionSite): void;
}

function footer(
  hideFooter: boolean,
  selectedBeaconIds: string[] | undefined,
  setSelectedBeaconIds: ((ids: string[]) => void) | undefined,
  t: TFunction,
  props:
    | BeaconTableWithServerSidePaginationProps
    | BeaconTableWithClientSidePaginationProps
) {
  return hideFooter
    ? undefined
    : () => (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto auto auto auto auto auto auto 1fr",
            gridColumnGap: "1em",
          }}
        >
          {selectedBeaconIds && setSelectedBeaconIds && (
            <Badge count={selectedBeaconIds.length} color="gray">
              <Button
                disabled={selectedBeaconIds.length === 0}
                onClick={() => setSelectedBeaconIds([])}
              >
                {t("construction-site:clear-selected-beacons")}
              </Button>
            </Badge>
          )}
          {props.footer}
        </div>
      );
}

export function ConstructionProjectBeaconsTable(
  props: ConstructionProjectBeaconsTableProps
) {
  const { t } = useTranslation();
  const isRoadSafetyManager = useContext(
    AuthContext
  ).currentUser?.accessToken.hasRole("road-safety-manager");

  const refresh = useCallback(() => {
    props.beacons.refresh();
    props.sites.refresh();
  }, [props.beacons.refresh, props.sites.refresh]);

  type BeaconPerConstructionSite = {
    key: string | undefined;
    items: ApiState<Beacon[]>;
  };

  const constructionSitesById = useTransformedState(
    props.sites.value,
    (sites) => toDictionary(sites ?? [], (site) => site.id)
  );

  const beaconsByConstructionSite: BeaconPerConstructionSite[] =
    useTransformedState(
      props.beacons.value,
      (beacons) =>
        groupBy(beacons ?? [], (b) => b.constructionSiteId)
          .map((g) => ({
            key: g.key,
            items: {
              loading: false,
              failed: false,
              refresh: async () => {},
              value: g.items,
            },
          }))
          .sort((c1, c2) => {
            if (!c1.key) {
              return 1;
            }
            if (!c2.key) {
              return -1;
            }

            try {
              const c1CreatedAt = constructionSitesById.get(c1.key)!.createdAt;
              const c2CreatedAt = constructionSitesById.get(c2.key)!.createdAt;
              return c1CreatedAt > c2CreatedAt ? 1 : -1;
            } catch {
              return 0;
            }
          }),
      constructionSitesById
    );

  const expandedRowRender = useCallback(
    (row: BeaconPerConstructionSite) => {
      const { beacons, footer, hideFooter, ...rest } = props;
      const {
        selectedBeaconIds,
        setSelectedBeaconIds,
        ...restWithoutBeaconSelection
      } = rest;
      return (
        <BeaconTable
          hideIccid
          size="small"
          pagination="client"
          hideFooter
          beacons={row.items}
          {...(isRoadSafetyManager ? rest : restWithoutBeaconSelection)}
        />
      );
    },
    [props]
  );

  const hasNoBeacons =
    props.beacons.value !== undefined &&
    props.beacons.value !== null &&
    !props.beacons.value?.length;

  if (
    beaconsByConstructionSite.length === 0 ||
    (beaconsByConstructionSite.length === 1 &&
      beaconsByConstructionSite[0].key === null)
  ) {
    const {
      selectedBeaconIds,
      setSelectedBeaconIds,
      ...propsWithoutBeaconSelection
    } = props;
    return (
      <BeaconTable
        hideIccid
        pagination="client"
        loading={props.beacons.loading}
        hideFooter
        {...propsWithoutBeaconSelection}
        noData={
          isRoadSafetyManager && hasNoBeacons ? (
            <AssignBeaconsModal
              constructionProjectId={props.constructionProjectId}
              defaultMapPosition={props.defaultMapPositionForBeaconAssignments}
              modalButton={(onOpen) => (
                <div
                  onClick={onOpen}
                  style={{
                    padding: 20,
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                >
                  <PlusCircleOutlined
                    className="primary-icon"
                    style={{ fontSize: "3em" }}
                  />
                  <div style={{ color: "black", marginTop: "1em" }}>
                    {t("construction-project:assign-beacons-long")}
                  </div>
                </div>
              )}
            />
          ) : (
            <></>
          )
        }
      />
    );
  }

  return (
    <Table
      sticky
      columns={[
        {
          title: t("construction-site"),
          render: (item: BeaconPerConstructionSite) =>
            item.key
              ? constructionSitesById.get(item.key)?.name || (
                  <Spin size="small" />
                )
              : t("construction-site:not-assigned"),
        },
        ...(isRoadSafetyManager
          ? [
              {
                title: t("actions"),
                width: "95px",
                render: (item: BeaconPerConstructionSite) =>
                  !!item.key && (
                    <ConstructionSiteActionMenu
                      constructionProjectId={props.constructionProjectId}
                      startEditing={() =>
                        props.startEditingConstructionSite(
                          constructionSitesById.get(item.key!)!
                        )
                      }
                      beacons={item.items.value}
                      refresh={refresh}
                      selectedBeaconIds={props.selectedBeaconIds}
                      setSelectedBeaconIds={props.setSelectedBeaconIds}
                    />
                  ),
              },
            ]
          : []),
      ]}
      size="middle"
      loading={props.beacons.loading}
      dataSource={beaconsByConstructionSite}
      pagination={{ hideOnSinglePage: true }}
      expandable={{ expandedRowRender }}
    ></Table>
  );
}

interface AllBeaconsTableProps {
  assignedToOrganization?: boolean;
  selectedBeaconIds?: string[];
  showConfigureButtons?: boolean;
  setSelectedBeaconIds?(ids: string[]): void;
  footer?: React.ReactNode;
  hideFooter?: boolean;
  showFilter?: boolean;
  additionalColumns?: ColumnType<Beacon>[];
  context?: string;
}

export function AllBeaconsTable(props: AllBeaconsTableProps) {
  const {
    showFilter,
    assignedToOrganization,
    additionalColumns,
    ...restProps
  } = props;

  const { filterValueForApi, filterValueForInput, onFilterValueChanged } =
    useDebouncedFilter();

  const [sorting, setSorting] = useState<BeaconTableSorting>({
    sortColumnId: undefined,
    sortOrderId: undefined,
  });

  const onSortingChange = useCallback(
    (
      property: keyof Beacon,
      direction: "descend" | "ascend" | null | undefined
    ) => {
      if (direction === "ascend") {
        setSorting({
          sortColumnId:
            /*
              When getting the column name (property) from the BeaconTable, the column name is in Camel Case, 
              so it is easier to map the enum to the column name. If the enum followed the standard convention
              of Pascal Case, then additional step to transform the column name to Pascal Case would be required, 
              or some other mapping mechanism would be required.
            */
            BeaconSortingColumn[property as keyof typeof BeaconSortingColumn],
          sortOrderId: BeaconSortingOrder.Ascending,
        });
      } else if (direction === "descend") {
        setSorting({
          sortColumnId:
            BeaconSortingColumn[property as keyof typeof BeaconSortingColumn],
          sortOrderId: BeaconSortingOrder.Descending,
        });
      } else {
        setSorting({
          sortColumnId: undefined,
          sortOrderId: undefined,
        });
      }
    },
    [setSorting]
  );

  const auth = useContext(AuthContext);
  const { t } = useTranslation();

  const beacons = useBeacons(
    assignedToOrganization,
    filterValueForApi,
    undefined,
    sorting
  );

  const onFilterChanged = useFilterValueChangedHandler(
    beacons,
    onFilterValueChanged
  );

  return (
    <BeaconTable
      header={
        showFilter ? (
          <div className="custom-title">
            <Input
              suffix={<SearchOutlined />}
              value={filterValueForInput}
              onChange={onFilterChanged}
              allowClear
              placeholder={t(
                "beacon:filter-placeholder" +
                  (auth.currentUser?.accessToken.hasRole("road-safety-manager")
                    ? "-rsm"
                    : "")
              )}
              style={{ width: "25em" }}
            />
            <BeaconsErrors
              severity={ErrorSeverity.Error}
              assignedToOrganization={assignedToOrganization}
              filter={filterValueForApi}
              style={{ marginLeft: "1em" }}
            />
            <BeaconsErrors
              severity={ErrorSeverity.Warning}
              assignedToOrganization={assignedToOrganization}
              filter={filterValueForApi}
              style={{ marginLeft: "1em" }}
            />
          </div>
        ) : undefined
      }
      beacons={beacons}
      pagination="server"
      hideIccid={true}
      sorting={sorting}
      onSort={onSortingChange}
      {...restProps}
    />
  );
}

interface PreloadedAllBeaconsTableProps {
  beacons: Beacon[];
  selectedBeaconIds?: string[];
  showConfigureButtons?: boolean;
  setSelectedBeaconIds?(ids: string[]): void;
  footer?: React.ReactNode;
  hideFooter?: boolean;
}

export function PreloadedAllBeaconsTable(props: PreloadedAllBeaconsTableProps) {
  const { beacons, ...rest } = props;
  const beaconsState: ApiState<Beacon[] | undefined> = useTransformedState(
    props.beacons,
    (b) => ({
      loading: false,
      failed: false,
      refresh: async () => {},
      value: b,
    })
  );
  return <BeaconTable beacons={beaconsState} pagination="client" {...rest} />;
}
