import { FromToFilter } from "../api";

/* 
    Determines a date range based on the provided lastLoadedAt date and selectedFromToFilter.

    lastLoadedAt: This parameter is a Date object or undefined. It represents the last time some 
    data was loaded.

    selectedFromToFilter: This parameter is of type FromToFilter. Based on the context, it can 
    either be a number (representing seconds) or an object with from and to properties.
*/
export const useComputeFromToDateRange = (
  lastLoadedAt: Date | undefined,
  selectedFromToFilter: FromToFilter
) => {
  const from =
    typeof selectedFromToFilter === "number"
      ? new Date(
          (lastLoadedAt || new Date()).getTime() - selectedFromToFilter * 1000
        )
      : selectedFromToFilter.from;

  const to =
    typeof selectedFromToFilter === "number"
      ? lastLoadedAt || new Date()
      : selectedFromToFilter.to;

  return { from, to };
};
