import { Table, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { BeaconError, ErrorSeverity, useErrorTranslation } from "../../../api";
import { PagedApiState } from "../../../api/useApiState";
import { toAntdPagination } from "../../../util";

export enum BeaconErrorTableColumns {
  Status = "status",
  ErrorText = "errorText",
  Severity = "severity",
  CreatedAt = "createdAt",
  ErrorCode = "errorCode",
  AdditionalData = "additionalData",
}

interface BeaconErrorTableProps {
  errorHistory: PagedApiState<BeaconError>;
  visibleColumns: BeaconErrorTableColumns[];
}

export function BeaconErrorTable({
  errorHistory,
  visibleColumns,
}: BeaconErrorTableProps) {
  const { t } = useTranslation();
  const errorTranslation = useErrorTranslation();

  const columns = [
    {
      key: "status",
      title: t("error:active-state"),
      width: "auto",
      render: (e: BeaconError) =>
        e.value.active ? (
          <Tag color="red">{t("error:active-state-active")}</Tag>
        ) : (
          <Tag color="green">{t("error:active-state-inactive")}</Tag>
        ),
    },
    {
      key: "errorText",
      title: t("error:text"),
      width: "auto",
      render: (e: BeaconError) =>
        errorTranslation(e.value.code, e.value.severity, !e.value.active),
    },
    {
      key: "severity",
      title: t("error:severity"),
      width: "auto",
      render: (e: BeaconError) =>
        e.value.severity === ErrorSeverity.Warning ? (
          <Tag color="orange">{t("error:severity-warning")}</Tag>
        ) : (
          <Tag color="red">{t("error:severity-error")}</Tag>
        ),
    },
    {
      key: "createdAt",
      title: t("error:measured-at"),
      render: (e: BeaconError) => e.measuredAt.toLocaleString(),
    },
    {
      key: "errorCode",
      title: t("error:code"),
      width: "auto",
      render: (e: BeaconError) => e.value.code,
    },
    {
      key: "additionalData",
      title: t("error:data"),
      render: (e: BeaconError) =>
        e.value.data ? JSON.stringify(e.value.data) : "",
    },
  ];

  /*
    Changing pattern for creating ant.design tables. Currently columns are defined in the 
    return statement of a component. This is not a good practice as it makes the code harder
    to read and leads to messy nested logic to display certain columns based on certain conditions.

    This pattern is changed by defining the columns in a separate array and then filtering them 
    based on the visibleColumns prop. This way the columns are defined in a single place and the
    logic to display certain columns is much cleaner.

    Recommended to update existing tables to this pattern.
  */
  const filteredColumns = columns.filter((column) =>
    visibleColumns.includes(column.key as BeaconErrorTableColumns)
  );

  return (
    <Table
      sticky
      style={{ gridColumn: "1 / -1" }}
      dataSource={errorHistory.value?.items || []}
      rowKey="id"
      size="middle"
      loading={errorHistory.loading}
      columns={filteredColumns}
      pagination={toAntdPagination(errorHistory, false)}
    ></Table>
  );
}
