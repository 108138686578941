import { Alert, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { Beacon, BeaconPosition, MarkerColor } from "../../../api";
import { CirclePosition, Map, MapPolyline } from "../../Map";
import { Maximizable } from "../../Maximizable";

interface BeaconDetailMapProps {
  loading: boolean;
  lastLoadedAt?: Date;
  beacon: Beacon;
  position?: BeaconPosition;
  polyLines?: MapPolyline[];
}

export function BeaconMapDetails({
  loading,
  beacon,
  position,
  polyLines,
}: BeaconDetailMapProps) {
  const { t } = useTranslation();

  const getMarkerColor = (): MarkerColor => {
    if (beacon.onOffSwitch?.isOn === false) {
      return "grey";
    }

    if (beacon.activeErrors?.length) {
      return "red";
    }

    return position?.value.md.cprs === 2 ? "green" : "orange";
  };

  const positionCircle: CirclePosition | undefined =
    position && position?.value?.md?.hAcc > 100
      ? {
          lat: position?.value.pos.lat!,
          lon: position?.value.pos.lon!,
          radiusInMeters: position!.value.md.hAcc / 1000,
        }
      : undefined;

  if (loading && !position) {
    return <Spin className="centered" aria-label="loading-spinner" />;
  }
  
  if (!position) {
    return (
      <>
        {loading && <Spin className="centered" />}
        <Alert
          style={{
            position: "relative",
            top: "10px",
          }}
          message={t("no-position")}
          type="info"
          showIcon
        />
      </>
    );
  }

  return (
    <Maximizable>
      <Map
        markers={[
          {
            lat: position.value.pos.lat,
            lon: position.value.pos.lon,
            color: getMarkerColor(),
            title: t("beacon:marker-title", {
              serial: beacon.serial || beacon.id,
              at: position.measuredAt.toLocaleString(),
            }),
          },
        ]}
        circle={positionCircle}
        polyLines={polyLines}
      />
    </Maximizable>
  );
}
