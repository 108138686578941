import { useHistory } from 'react-router-dom';
import { AmplifyLoadingSpinner } from '@aws-amplify/ui-react';
import { Button, Input, Table, Tag } from 'antd';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { ConstructionProject, useConstructionProjects, useCreateConstructionProject, ConstructionProjectMutableProperties } from '../../api';
import { toAntdPagination, useDebouncedFilter, useFilterValueChangedHandler } from '../../util';
import { useTranslation } from 'react-i18next';
import { Address as AddressComponent } from '../../components/Address';
import { ConstructionProjectPropertyModal } from '../../components/ConstructionProjectPropertyModal';
import { useCallback, useContext, useState } from 'react';
import { ActionsDropdown } from '../construction-project/ActionsDropdown';
import { AuthContext } from '../../contexts/authContext';

export function ConstructionProjects() {
    const history = useHistory();
    const { t } = useTranslation();
    const isRoadSafetyManager = useContext(AuthContext).currentUser?.accessToken.hasRole('road-safety-manager');
    const isSolutionOperator = useContext(AuthContext).currentUser?.accessToken.hasRole('solution-operator');

    const { filterValueForApi, filterValueForInput, onFilterValueChanged } = useDebouncedFilter();
    const [archived, setArchived] = useState<boolean | undefined>(false);
    const showArchived = useCallback(() => setArchived(true), [setArchived]);
    const showActive = useCallback(() => setArchived(false), [setArchived]);
    const showAll = useCallback(() => setArchived(undefined), [setArchived]);

    const constructionProjects = useConstructionProjects(filterValueForApi, archived);
    const onFilterChanged = useFilterValueChangedHandler(constructionProjects, onFilterValueChanged);

    const createConstructionProject = useCreateConstructionProject();
    const onCreateConstructionProject = async (project: ConstructionProjectMutableProperties) => {
        const constructionProject = await createConstructionProject(project);
        history.push('/construction-projects/' + constructionProject.id);
    };

    const [isDeletingOrArchiving, setIsDeletingOrArchiving] = useState(false);

    if (!constructionProjects.loading && !constructionProjects.value) {
        return <AmplifyLoadingSpinner />;
    }

    return <Table
        sticky
        className="with-custom-title"
        title={() => <div className="custom-title" style={{ display: 'grid', gridTemplateColumns: 'auto auto 1fr auto' }}>
            <Input suffix={<SearchOutlined />} value={filterValueForInput} onChange={onFilterChanged} allowClear placeholder={t('construction-project:filter-placeholder')} style={{ width: '20em' }} />
            <div className="multi-button-container" style={{ marginLeft: '1em' }}>
                <Button onClick={showActive} type={archived === false ? 'primary' : 'default'}>{t('construction-project:only-active')}</Button>
                <Button onClick={showArchived} type={archived === true ? 'primary' : 'default'}>{t('construction-project:only-archived')}</Button>
                <Button onClick={showAll} type={archived === undefined ? 'primary' : 'default'}>{t('construction-project:all')}</Button>
            </div>
            <div></div>
            {isRoadSafetyManager && <ConstructionProjectPropertyModal title={t('construction-project:new')} modalButtonIcon={<PlusCircleOutlined />} onSave={onCreateConstructionProject} />}
        </div>}
        dataSource={constructionProjects.value?.items || []}
        rowKey="id"
        size="middle"
        loading={constructionProjects.loading || isDeletingOrArchiving}
        onRow={record => {
            return {
                onClick: e => {
                    if (e.defaultPrevented) {
                        return;
                    }
                    e.preventDefault();
                    history.push('/construction-projects/' + record.id);
                },
                style: {
                    cursor: 'pointer'
                }
            };
        }}
        columns={[
            {
                title: t('construction-project:name'),
                render: (c: ConstructionProject) => <span>{c.name}{c.createdAutomatically ? ` (${t('construction-project:created-automatically')})` : ''}{archived === undefined && c.isArchived && <Tag color="warning" style={{ marginLeft: '1em' }}>{t('construction-project:archived')}</Tag>}</span>
            },
            {
                title: t('construction-project:address'),
                render: (c: ConstructionProject) => <AddressComponent address={c.address} />
            },
            ...(isRoadSafetyManager
                ? [{
                    title: t('actions'),
                    render: (c: ConstructionProject) => <ActionsDropdown
                        constructionProjectId={c.id}
                        constructionProject={c}
                        onIsDeletingOrArchiving={setIsDeletingOrArchiving}
                        onDeleted={constructionProjects.refresh}
                        refresh={constructionProjects.refresh} />
                }]
                : isSolutionOperator
                    ? [{
                        title: t('construction-project:organization-name'),
                        render: (c: ConstructionProject) => !!c.organizationId && !!c.organizationName && <a href={`/organizations/${c.organizationId}`} onClick={e => { e.preventDefault(); history.push(`/organizations/${c.organizationId}`) }}>{c.organizationName}</a>
                    }]
                    : [])
        ]}
        pagination={toAntdPagination(constructionProjects)}>
    </Table>;
}