import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { Beacon, BeaconEnvironment } from "../../../api";
import { ApiState } from "../../../api/useApiState";
import { Chart, SeriesDefintion } from "../../Chart";
import { DownloadEnvironmentMessages } from "../../DownloadEnvironmentMessages";

interface BeaconBatteryDrawerProps {
  beaconId: Beacon["id"];
  environmentHistory: ApiState<BeaconEnvironment[] | undefined>;
  environmentFromToDateRange: {
    from: Date;
    to: Date;
  };
  environmentSeries: SeriesDefintion<BeaconEnvironment>[];
  batteryDrawerVisible: boolean;
  onSelectCustomTimeFrame: (from: Date, to: Date) => void;
  onCloseDrawer: (visible: boolean) => void;
}

export function BeaconBatteryDrawer({
  beaconId,
  environmentHistory,
  environmentFromToDateRange,
  environmentSeries,
  onSelectCustomTimeFrame,
  batteryDrawerVisible,
  onCloseDrawer,
}: BeaconBatteryDrawerProps) {
  const { t } = useTranslation();

  const closeDrawer = () => {
    onCloseDrawer(false);
  };

  return (
    <Drawer
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>{t("battery-voltage")}</span>
          <Button
            type="text"
            className="close-card-drawer-button"
            icon={<CloseOutlined />}
            onClick={closeDrawer}
          />
        </div>
      }
      placement="right"
      closable={false}
      onClose={closeDrawer}
      visible={batteryDrawerVisible}
      width={1200}
    >
      <Chart
        onZoom={onSelectCustomTimeFrame}
        items={environmentHistory.value || []}
        loading={environmentHistory.loading}
        series={environmentSeries}
        from={environmentFromToDateRange.from}
        to={environmentFromToDateRange.to}
      />
      {!!environmentHistory.value?.length && (
        <DownloadEnvironmentMessages
          beaconId={beaconId}
          environmentMessages={environmentHistory.value || []}
          style={{ position: "absolute", bottom: 10, right: 40 }}
          from={environmentFromToDateRange.from}
          to={environmentFromToDateRange.to}
        />
      )}
    </Drawer>
  );
}
