import { Breadcrumb, Spin, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useBeacon } from "../../api";
import { BeaconConfigurationModal } from "../../components/beacon-configuration/BeaconConfigurationModal";
import { BeaconDetails } from "../../components/beacon-details/BeaconDetails";

export function Beacon() {
  const { t } = useTranslation();
  const history = useHistory();
  const { beaconId } = useParams<{ beaconId: string }>();
  const beacon = useBeacon(beaconId);

  if (!beacon.value) {
    return (
      <Spin className="centered" aria-label="loading-spinner" size="large" />
    );
  }

  return (
    <BeaconDetails
      beacon={beacon.value}
      breadcrumb={
        <Breadcrumb>
          <Breadcrumb.Item>
            <a
              href="/beacons"
              onClick={(e) => {
                e.preventDefault();
                history.push("/beacons");
              }}
            >
              {t("beacons")}
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{ marginRight: "1em" }}>
              {beacon.value ? beacon.value.serial || beacon.value.id : beaconId}
            </span>
            <span style={{ marginRight: "1em" }}>
              {beacon.value && (
                <BeaconConfigurationModal
                  beacon={beacon.value}
                  style={{ fontSize: "large" }}
                />
              )}
            </span>
            {beacon.value.isDisabled && (
              <span style={{ marginRight: "1em" }}>
                <Tag color="red" style={{ marginLeft: "1em" }}>
                  {t("beacon:disabled")}
                </Tag>
              </span>
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
      }
    />
  );
}
