import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { Beacon, BeaconPosition } from "../../../api";
import { ApiState } from "../../../api/useApiState";
import { SeriesDefintion } from "../../Chart";
import { DownloadPositions } from "../../DownloadPositions";
import { DualAxisLineChart } from "../../MultiAxisChart";

interface BeaconLocationDrawerProps {
  beaconId: Beacon["id"];
  positions: BeaconPosition[];
  positionHistory: ApiState<BeaconPosition[] | undefined>;
  positionSeries: SeriesDefintion<BeaconPosition>[];
  locationDrawerVisible: boolean;
  positionFromToDateRange: {
    from: Date;
    to: Date;
  };
  onSelectCustomTimeFrame: (from: Date, to: Date) => void;
  onCloseDrawer: (visible: boolean) => void;
}

export function BeaconLocationDrawer({
  beaconId,
  positions,
  positionHistory,
  positionSeries,
  locationDrawerVisible,
  positionFromToDateRange,
  onSelectCustomTimeFrame,
  onCloseDrawer,
}: BeaconLocationDrawerProps) {
  const { t } = useTranslation();

  const closeDrawer = () => {
    onCloseDrawer(false);
  };

  return (
    <Drawer
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {t("beacon:position-data")}
          <Button
            type="text"
            className="close-card-drawer-button"
            icon={<CloseOutlined />}
            onClick={closeDrawer}
          />
        </div>
      }
      placement="right"
      closable={false}
      onClose={closeDrawer}
      visible={locationDrawerVisible}
      width={1200}
    >
      <DualAxisLineChart
        onZoom={onSelectCustomTimeFrame}
        items={positions}
        loading={positionHistory.loading}
        series={positionSeries.filter((_, index) => index === 0 || index === 4)}
        from={positionFromToDateRange.from}
        to={positionFromToDateRange.to}
      />
      {!!positions.length && (
        <DownloadPositions
          beaconId={beaconId}
          positions={positions}
          style={{ position: "absolute", bottom: 10, right: 40 }}
          from={positionFromToDateRange.from}
          to={positionFromToDateRange.to}
        />
      )}
    </Drawer>
  );
}
