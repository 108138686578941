import { Beacon } from "../../api";
import { useSetting } from "../../util";
import {
  BeaconTelemetryFilter,
  BeaconTelemetryFilterValue,
} from "../BeaconTelemetryFilter";
import {
  TimeRangeSelection,
  useTimeRangeSelection,
} from "../TimeRangeSelection";
import { BeaconAboutCard } from "./about/BeaconAboutCard";
import { BeaconBatteryCard } from "./battery/BeaconBatteryCard";
import { BeaconErrorCard } from "./error/BeaconErrorCard";
import { BeaconEventCard } from "./event/BeaconEventCard";
import { BeaconLocationCard } from "./location/BeaconLocationCard";
import { BeaconMapCard } from "./map/BeaconMapCard";

interface BeaconProps {
  constructionProjectId?: string;
  beacon: Beacon;
  breadcrumb: React.ReactElement;
}

export function BeaconDetails({ beacon, breadcrumb }: BeaconProps) {
  const [filter, setFilter] = useSetting<BeaconTelemetryFilterValue>(
    "beacon:telemetry-filter",
    "ShowAll"
  );

  const {
    selectedFromToFilter,
    fromToFilterOptions,
    selectedFromToFilterOptionIndex,
    onSelectFilterOption,
    onSelectCustomTimeFrame,
  } = useTimeRangeSelection(beacon.id);

  return (
    <div className="screen-with-breadcrumb">
      <div className="screen-with-filter-header">
        <div style={{ justifySelf: "start" }}>{breadcrumb}</div>
        <div style={{ justifySelf: "center" }}>
          <TimeRangeSelection
            fromToFilterOptions={fromToFilterOptions}
            selectedFromToFilterOptionIndex={selectedFromToFilterOptionIndex}
            onSelectFilterOption={onSelectFilterOption}
            onSelectCustomTimeFrame={onSelectCustomTimeFrame}
          />
        </div>
        <div style={{ justifySelf: "end", marginLeft: "1rem" }}>
          <BeaconTelemetryFilter filter={filter} onFilterChanged={setFilter} />
        </div>
      </div>
      <div className="card-container">
        <BeaconAboutCard beacon={beacon} />
        <BeaconMapCard
          beacon={beacon}
          selectedFromToFilter={selectedFromToFilter}
          filter={filter}
        />
        <BeaconErrorCard
          beacon={beacon}
          selectedFromToFilter={selectedFromToFilter}
        />
        <BeaconEventCard
          beacon={beacon}
          selectedFromToFilter={selectedFromToFilter}
        />
        <BeaconLocationCard
          selectedFromToFilter={selectedFromToFilter}
          filter={filter}
          onSelectCustomTimeFrame={onSelectCustomTimeFrame}
          beacon={beacon}
        />
        <BeaconBatteryCard
          beacon={beacon}
          selectedFromToFilter={selectedFromToFilter}
          onSelectCustomTimeFrame={onSelectCustomTimeFrame}
        />
      </div>
    </div>
  );
}
