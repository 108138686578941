 import { PlusOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Beacon, FromToFilter, useErrorHistory } from "../../../api";
import "../../../styles/card.scss";
import { BeaconErrorDrawer } from "./BeaconErrorDrawer";
import { BeaconErrorTable, BeaconErrorTableColumns } from "./BeaconErrorTable";

interface BeaconErrorCardProps {
  beacon: Beacon;
  selectedFromToFilter:
    | (FromToFilter & number)
    | (FromToFilter & {
        from: Date;
        to: Date;
      });
} 

export function BeaconErrorCard({
  beacon,
  selectedFromToFilter,
}: BeaconErrorCardProps) {

  const [errorDrawerVisible, setErrorDrawerVisible] = useState(false);
  const { t } = useTranslation();
  const errorHistory = useErrorHistory(beacon.id, selectedFromToFilter);
  const handleDrawerOpenClose = (state: boolean) => {
    setErrorDrawerVisible(state);
  };
  return (
    <div className="card">
      <div className="card-header">{t("beacon:error-details")}</div>
      <Tabs
        style={{ height: "100%", marginBottom: "40px" }}
        className="fullsize-tabs"
      >
        <Tabs.TabPane
          key="err"
          style={{ height: "100%", position: "absolute" }}
        >
          <BeaconErrorTable
            errorHistory={errorHistory}
            visibleColumns={[
              BeaconErrorTableColumns.Status,
              BeaconErrorTableColumns.ErrorText,
              BeaconErrorTableColumns.ErrorCode,
              BeaconErrorTableColumns.Severity,
            ]}
          />
        </Tabs.TabPane>
      </Tabs>
      <BeaconErrorDrawer
        beaconId={beacon.id}
        errorHistory={errorHistory}
        errorDrawerVisible={errorDrawerVisible}
        onCloseDrawer={handleDrawerOpenClose}
      />
      <Button
        aria-label="show-drawer-button"
        className="show-card-drawer-button"
        type="primary"
        onClick={() => handleDrawerOpenClose(true)}
        icon={<PlusOutlined />}
      ></Button>
    </div>
  );
}
