import { Breadcrumb, Button, Menu, Spin, Tag } from "antd";
import { Map as LeafletMap } from "leaflet";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  ErrorSeverity,
  useBeaconsInArea,
  useConstructionProject,
  useConstructionProjectBeacons,
  useConstructionProjectSites,
} from "../../api";
import { Badge } from "../../components/Badge";
import { BeaconActiveErrorsTooltip } from "../../components/BeaconActiveErrorsTooltip";
import { ConstructionProjectBeaconsTable } from "../../components/BeaconTable";
import { DrawingInstruction } from "../../components/DrawingInstruction";
import {
  CirclePosition,
  getDefaultMapPosition,
  Map as MapComponent,
  MapPolygon,
  MapPolyline,
  MapPosition,
  MarkerPosition,
} from "../../components/Map";
import { Maximizable } from "../../components/Maximizable";
import { PolygonEditControl } from "../../components/PolygonEditControl";
import { AuthContext } from "../../contexts/authContext";
import { useBeaconsByConstructionSite, useRefreshAll } from "../../util";
import { useTransformedState } from "../../util/useTransformedState";
import { ActionsDropdown } from "./ActionsDropdown";
import { AssignBeaconsModal } from "./AssignBeaconsModal";
import { ConstructionProjectBeaconTableFooter } from "./ConstructionProjectBeaconTableFooter";
import { UnassignBeaconsModal } from "./UnassignBeaconsModal";
import { useConstructionSiteEditMode } from "./useConstructionSiteEditMode";

export function ConstructionProject() {
  const { constructionProjectId } = useParams<{
    constructionProjectId: string;
  }>();
  const constructionProject = useConstructionProject(constructionProjectId);
  const constructionSites = useConstructionProjectSites(constructionProjectId);
  const { t } = useTranslation();
  const history = useHistory();
  const isRoadSafetyManager = !!useContext(
    AuthContext
  ).currentUser?.accessToken.hasRole("road-safety-manager");
  const onDeleted = useCallback(
    () => history.push("/construction-projects"),
    [history]
  );

  const [isDeletingOrArchiving, setIsDeletingOrArchiving] = useState(false);

  const beaconsInConstructionProject = useConstructionProjectBeacons(
    constructionProjectId
  );
  const [selectedBeaconIds, setSelectedBeaconIds] = useState<string[]>([]);
  const isBeaconSelected = useCallback(
    (beaconId: string) => {
      const index = selectedBeaconIds.indexOf(beaconId);
      return index >= 0;
    },
    [selectedBeaconIds]
  );
  const toggleBeaconSelection = useCallback(
    (beaconId: string) => {
      setSelectedBeaconIds((ids) => {
        const index = ids.indexOf(beaconId);
        if (index >= 0) {
          return ids.filter((i) => i !== beaconId);
        }
        return [...ids, beaconId];
      });
    },
    [setSelectedBeaconIds]
  );

  const area = constructionProject?.value?.area;
  const areaFilter = useTransformedState(area, (a) =>
    a ? { area: a } : undefined
  );
  const beaconsInArea = useBeaconsInArea(areaFilter);

  const beaconsOutsideArea = (beaconsInConstructionProject.value || []).filter(
    (b) =>
      beaconsInArea.value && beaconsInArea.value.every((a) => a.id !== b.id)
  );
  const beaconsInAreaNotInProject = (beaconsInArea.value || []).filter(
    (b) =>
      beaconsInConstructionProject.value &&
      beaconsInConstructionProject.value.every((a) => a.id !== b.id)
  );

  const reload = useRefreshAll(
    beaconsInConstructionProject,
    beaconsInArea,
    constructionSites
  );
  const [map, setMap] = useState<LeafletMap>();
  const constructionSiteEditMode = useConstructionSiteEditMode(
    constructionProjectId,
    reload,
    map
  );

  const beaconsByConstructionSite = useBeaconsByConstructionSite(
    beaconsInConstructionProject.value
  );
  if (!constructionProject.value || isDeletingOrArchiving) {
    return <Spin size="large" />;
  }

  const markers: MarkerPosition[] =
    beaconsInConstructionProject.value
      ?.filter((p) => !!p.position)
      ?.map((p) => ({
        lat: p.position.value.pos.lat,
        lon: p.position.value.pos.lon,
        color: isBeaconSelected(p.id) ? "green" : "blue",
        title: p.serial || p.id,
        hasError: p.activeErrors?.length ? true : undefined,
        errorText: p.activeErrors?.length ? (
          <BeaconActiveErrorsTooltip errors={p.activeErrors} />
        ) : undefined,
        errorSeverity: p.activeErrors?.some(
          (e) => e.severity === ErrorSeverity.Error
        )
          ? "error"
          : "warning",
        onClick:
          isRoadSafetyManager && p.constructionSiteId
            ? () => toggleBeaconSelection(p.id)
            : undefined,
      })) || [];

  if (beaconsInAreaNotInProject?.length) {
    beaconsInAreaNotInProject.forEach((b) => {
      markers.push({
        lat: b.position.value.pos.lat,
        lon: b.position.value.pos.lon,
        title: t("construction-project:beacon-not-assigned", {
          beacon: b.serial || b.id,
        }),
        color: "grey",
      });
    });
  }

  const csPolygons: MapPolygon[] = constructionSites.value?.length
    ? constructionSites.value
        .filter(
          (cs) =>
            !!cs.polygon &&
            cs.id !== constructionSiteEditMode.editedConstructionSite?.id
        )
        .map((cs) => ({
          color: "orange",
          ring: cs.polygon!,
          onClick: isRoadSafetyManager
            ? () => constructionSiteEditMode.edit(cs)
            : undefined,
          title: cs.name,
        }))
    : [];

  const csCircles: CirclePosition[] = constructionSites.value?.length
    ? constructionSites.value
        .filter(
          (cs) =>
            !cs.polygon &&
            beaconsByConstructionSite.has(cs.id) &&
            beaconsByConstructionSite.get(cs.id)?.length === 1 &&
            beaconsByConstructionSite.get(cs.id)![0]?.position?.value?.pos
        )
        .map((cs) => {
          const beacons = beaconsByConstructionSite.get(cs.id)!;
          return {
            color: "orange",
            lat: beacons[0].position.value.pos.lat,
            lon: beacons[0].position.value.pos.lon,
            title: cs.name,
            radiusInMeters: 3,
            onClick: isRoadSafetyManager
              ? () => constructionSiteEditMode.edit(cs)
              : undefined,
          };
        })
        .filter((l) => !!l)
    : [];

  const csLines: MapPolyline[] = constructionSites.value?.length
    ? constructionSites.value
        .filter(
          (cs) =>
            !cs.polygon &&
            beaconsByConstructionSite.has(cs.id) &&
            (beaconsByConstructionSite.get(cs.id)?.length ?? 0) > 1
        )
        .map((cs) => {
          const beacons = beaconsByConstructionSite
            .get(cs.id)!
            .filter((b) => !!b?.position?.value?.pos);
          return {
            color: "orange",
            line: beacons.map((b) => ({
              lat: b.position.value.pos.lat,
              lon: b.position.value.pos.lon,
            })),
            title: cs.name,
            width: 8,
            onClick: isRoadSafetyManager
              ? () => constructionSiteEditMode.edit(cs)
              : undefined,
          };
        })
        .filter((l) => l.line.length)
    : [];

  const defaultMapPositionForBeaconAssignments: MapPosition | undefined =
    constructionProject.value?.area
      ? getDefaultMapPosition(undefined, [
          {
            ring: constructionProject.value.area,
          },
        ])
      : undefined;

  const additionalActions: React.ReactNode[] = [];
  if (isRoadSafetyManager) {
    if (beaconsInAreaNotInProject?.length) {
      additionalActions.push(
        <Menu.Item key="assign" className="make-badge-full-width">
          <Badge count={beaconsInAreaNotInProject.length}>
            <AssignBeaconsModal
              title={t("construction-project:assign-beacons-by-area", {
                count: beaconsInAreaNotInProject.length,
              })}
              constructionProjectId={constructionProjectId}
              beacons={beaconsInAreaNotInProject}
            />
          </Badge>
        </Menu.Item>
      );
    } else if (!constructionProject.value?.area) {
      additionalActions.push(
        <Menu.Item key="assign">
          <AssignBeaconsModal
            title={t("construction-project:assign-beacons_plural")}
            constructionProjectId={constructionProjectId}
          />
        </Menu.Item>
      );
    }

    if (beaconsOutsideArea?.length) {
      additionalActions.push(
        <Menu.Item key="unassign" className="make-badge-full-width">
          <Badge count={beaconsOutsideArea.length}>
            <UnassignBeaconsModal
              title={t("construction-project:unassign-beacons-by-area", {
                count: beaconsOutsideArea.length,
              })}
              constructionProjectId={constructionProjectId}
              beacons={beaconsOutsideArea}
            />
          </Badge>
        </Menu.Item>
      );
    }
  }

  return (
    <div className="screen-with-breadcrumb">
      <div style={{ position: "relative" }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a
              href="/construction-projects"
              onClick={(e) => {
                e.preventDefault();
                history.push("/construction-projects");
              }}
            >
              {t("construction-projects")}
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {constructionProject.value.name}
            {constructionProject.value.createdAutomatically
              ? ` (${t("construction-project:created-automatically")})`
              : ""}
            {constructionProject.value.isArchived && (
              <Tag color="warning" style={{ marginLeft: "1em" }}>
                {t("construction-project:archived")}
              </Tag>
            )}
          </Breadcrumb.Item>
        </Breadcrumb>
        {isRoadSafetyManager && (
          <div style={{ position: "absolute", right: 10, top: -3 }}>
            <ActionsDropdown
              constructionProjectId={constructionProjectId}
              constructionProject={constructionProject.value}
              additionalActions={additionalActions}
              onDeleted={onDeleted}
              refresh={constructionProject.refresh}
              onIsDeletingOrArchiving={setIsDeletingOrArchiving}
              badgeCount={
                beaconsOutsideArea.length + beaconsInAreaNotInProject.length
              }
            />
          </div>
        )}
      </div>
      <div
        className="hide-leaflet-draw"
        style={{
          display: "grid",
          gridTemplateColumns: "minmax(auto, 376px) 1fr",
          gridColumnGap: "1em",
        }}
      >
        <div
          style={{
            overflowY: "auto",
            overflowX: "visible",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              width: "100%",
              top: 0,
              height: "100%",
            }}
          >
            <ConstructionProjectBeaconsTable
              {...{
                constructionProjectId,
                selectedBeaconIds,
                setSelectedBeaconIds,
                beacons: beaconsInConstructionProject,
                sites: constructionSites,
                defaultMapPositionForBeaconAssignments,
              }}
              startEditingConstructionSite={constructionSiteEditMode.edit}
              footer={
                <ConstructionProjectBeaconTableFooter
                  {...{
                    constructionProjectId,
                    selectedBeaconIds,
                    reload,
                    defaultMapPositionForBeaconAssignments,
                  }}
                  onClearSelectedBeaconIds={() => setSelectedBeaconIds([])}
                  constructionSites={constructionSites.value}
                />
              }
            />
          </div>
        </div>
        <Maximizable
          buttonPosition="bottom-right"
          label={beaconsInConstructionProject.loading ? <Spin /> : undefined}
        >
          <>
            <MapComponent
              markers={markers}
              allowNoMarkers
              polygons={
                constructionProject.value.area
                  ? [
                      {
                        ring: constructionProject.value.area,
                        color: "rgb(51, 136, 255)",
                      },
                      ...csPolygons,
                    ]
                  : undefined
              }
              circles={csCircles}
              polyLines={csLines}
              onMapReady={setMap}
              controls={
                !!isRoadSafetyManager &&
                constructionSiteEditMode.isOpen && (
                  <PolygonEditControl
                    map={map}
                    area={constructionSiteEditMode.area}
                    onAreaChanged={constructionSiteEditMode.setArea}
                  />
                )
              }
            />
            {isRoadSafetyManager && !constructionSiteEditMode.isOpen && (
              <Button
                onClick={constructionSiteEditMode.create}
                type="primary"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "11px",
                  zIndex: 1000,
                }}
              >
                {t("construction-site:new")}
              </Button>
            )}
            {isRoadSafetyManager && constructionSiteEditMode.isOpen && (
              <div
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "11px",
                  zIndex: 1000,
                }}
              >
                <Button
                  onClick={constructionSiteEditMode.cancel}
                  disabled={constructionSiteEditMode.isSaving}
                  style={{ width: "8em", marginRight: "1em" }}
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={
                    constructionSiteEditMode.isSaving
                      ? undefined
                      : constructionSiteEditMode.save
                  }
                  loading={constructionSiteEditMode.isSaving}
                  type="primary"
                  style={{ width: "8em" }}
                >
                  {t("save")}
                </Button>
              </div>
            )}
            {isRoadSafetyManager && constructionSiteEditMode.isOpen && (
              <DrawingInstruction
                title={t("construction-site:drawing-tooltip")}
              />
            )}
          </>
        </Maximizable>
      </div>
    </div>
  );
}
