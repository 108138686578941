import { MinusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUnassignBeaconsFromOrganization } from "../../api";
import { Badge } from "../../components/Badge";
import { DataRefreshContext } from "../../contexts/dataRefreshContext";
import { AssignBeaconsModal } from "./AssignBeaconsModal";

interface OrganizationBeaconTableFooterProps {
  organizationId: string;
  selectedBeaconIds: string[];
  onClearSelectedBeaconIds(): void;
}

export function OrganizationBeaconTableFooter(
  props: OrganizationBeaconTableFooterProps
) {
  const { organizationId, selectedBeaconIds } = props;
  const [isUnassigning, setIsUnassigning] = useState(false);
  const unassignBeaconsFromOrganization = useUnassignBeaconsFromOrganization();
  const { t } = useTranslation();
  const dataRefreshContext = useContext(DataRefreshContext);

  const onUnassignBeaconsFromOrganization = async () => {
    try {
      setIsUnassigning(true);
      await unassignBeaconsFromOrganization({
        beaconIds: selectedBeaconIds,
        organizationId,
      });
      props.onClearSelectedBeaconIds();
      dataRefreshContext.refresh();
    } finally {
      setIsUnassigning(false);
    }
  };

  return (
    <>
      <Badge count={selectedBeaconIds.length}>
        <Button
          danger
          disabled={selectedBeaconIds.length === 0}
          loading={isUnassigning}
          onClick={onUnassignBeaconsFromOrganization}
          title={t("organization:unassign-beacons-message", {
            count: selectedBeaconIds.length,
          })}
        >
          <MinusCircleOutlined />
          {t("organization:unassign-beacons", {
            count: selectedBeaconIds.length,
          })}
        </Button>
      </Badge>

      <AssignBeaconsModal organizationId={organizationId} />
    </>
  );
}
