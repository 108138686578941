import { fixDatesOfPagedBeacons } from "./api.beacons";
import {
    Beacon,
    BeaconTableSorting,
    Organization,
    OrganizationMutableProperties,
    PagedResponse,
    User,
} from "./models";
import {
    ApiCall,
    ApiState,
    PagedApiState,
    useApiState,
    useAuthorizedFetch,
    useGetAuthorized,
    usePagedApiState,
} from "./useApiState";

export function useOrganizations(): PagedApiState<Organization> {
  return usePagedApiState(
    useGetAuthorized<PagedResponse<Organization>>("organizations")
  );
}

export function useOrganization(
  organizationId: string
): ApiState<Organization | undefined> {
  return useApiState(
    useGetAuthorized<Organization>(`organizations/${organizationId}`),
    undefined
  );
}

export function useMyOrganization(): ApiState<Organization | undefined> {
  return useApiState(
    useGetAuthorized<Organization>("organizations/my"),
    undefined
  );
}

export function useCreateOrganization(): ApiCall<
  OrganizationMutableProperties,
  Organization
> {
  const authorizedFetch = useAuthorizedFetch();
  return async (organization: OrganizationMutableProperties) => {
    const result = await authorizedFetch("organizations", {
      method: "post",
      body: JSON.stringify(organization),
    });
    return (await result.json()) as Organization;
  };
}

export function useUpdateOrganization(
  organizationId: string
): ApiCall<OrganizationMutableProperties, Organization> {
  const authorizedFetch = useAuthorizedFetch();
  return async (organization: OrganizationMutableProperties) => {
    const result = await authorizedFetch(`organizations/${organizationId}`, {
      method: "put",
      body: JSON.stringify(organization),
    });
    return (await result.json()) as Organization;
  };
}

export function useDeleteOrganization(
  organizationId: string
): ApiCall<void, void> {
  const authorizedFetch = useAuthorizedFetch();
  return async () => {
    const result = await authorizedFetch(`organizations/${organizationId}`, {
      method: "delete",
    });
    if (!result.ok) {
      throw result.status;
    }
  };
}

export function useOrganizationUsers(
  organizationId: string
): PagedApiState<User> {
  return usePagedApiState(
    useGetAuthorized<PagedResponse<User>>(
      `organizations/${organizationId}/users`
    )
  );
}

export function useUsers(
  assignedToOrganization?: boolean
): PagedApiState<User> {
  const query =
    assignedToOrganization === undefined
      ? ""
      : `?assignedToOrganization=${assignedToOrganization}`;
  return usePagedApiState(
    useGetAuthorized<PagedResponse<User>>("users" + query)
  );
}

export function useAssignUsersToOrganization(): ApiCall<
  { userIds: string[]; organizationId: string },
  void
> {
  const authorizedFetch = useAuthorizedFetch();
  return async (a: { userIds: string[]; organizationId: string }) => {
    const result = await authorizedFetch(
      `organizations/${a.organizationId}/users`,
      { method: "post", body: JSON.stringify(a.userIds) }
    );
    if (!result.ok) {
      throw result.status;
    }
  };
}

export function useUnassignUsersFromOrganization(): ApiCall<
  { userIds: string[]; organizationId: string },
  void
> {
  const authorizedFetch = useAuthorizedFetch();
  return async (a: { userIds: string[]; organizationId: string }) => {
    const result = await authorizedFetch(
      `organizations/${a.organizationId}/users`,
      { method: "delete", body: JSON.stringify(a.userIds) }
    );
    if (!result.ok) {
      throw result.status;
    }
  };
}

export function useOrganizationBeacons(
  organizationId: string,
  sorting?: BeaconTableSorting
): PagedApiState<Beacon> {
  return usePagedApiState(
    useGetAuthorized<PagedResponse<Beacon>>(
      `organizations/${organizationId}/beacons?sort=${sorting?.sortColumnId}&order=${sorting?.sortOrderId}`,
      fixDatesOfPagedBeacons
    )
  );
}

export function useAssignBeaconsToOrganization(): ApiCall<
  { beaconIds: string[]; organizationId: string },
  void
> {
  const authorizedFetch = useAuthorizedFetch();
  return async (a: { beaconIds: string[]; organizationId: string }) => {
    const result = await authorizedFetch(
      `organizations/${a.organizationId}/beacons`,
      { method: "post", body: JSON.stringify(a.beaconIds) }
    );
    if (!result.ok) {
      throw result.status;
    }
  };
}

export function useUnassignBeaconsFromOrganization(): ApiCall<
  { beaconIds: string[]; organizationId: string },
  void
> {
  const authorizedFetch = useAuthorizedFetch();
  return async (a: { beaconIds: string[]; organizationId: string }) => {
    const result = await authorizedFetch(
      `organizations/${a.organizationId}/beacons`,
      { method: "delete", body: JSON.stringify(a.beaconIds) }
    );
    if (!result.ok) {
      throw result.status;
    }
  };
}
